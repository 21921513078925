export default (state, action) => {
  switch (action.type) {
    case "LOGGED_IN":
      return {
        user: action.data,
        cookieChecked: true,
      };
    case "LOGGED_OUT":
      return {
        ...state,
        user: undefined
      };
    case "WRONG_CREDENTIALS":
      return {
        error: {
          title: "Algo deu errado:",
          label: "Usuário e/ou senha inválidos, tente novamente.",
        },
      };
    case "COOKIE_CHECKED":
      return {
        user: action.data,
        cookieChecked: true,
      };
    case "APPROVED_ALPHA_KEY":
      return {
        ...state,
        alphaKeyEmail: action.data,
        alphaKeyError: undefined
      }
    case "WRONG_ALPHA_KEY":
      return {
        alphaKeyError: "A chave alfa é invalida."
      }
    default:
      return state;
  }
};
