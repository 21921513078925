export default (state, action) => {
  switch (action.type) {
    case "SELECT_PROJECT":
      return {
        ...state,
        currentBuilding: action.data
      }
    case "SELECT_CUB_FILTER":
      return {
        ...state,
        cubFilter: action.data
      }
    case "UPDATE_ALL_CUBS":
      return {
        ...state,
        allCubs: action.data
      }
    default:
      return state;
  }
};
