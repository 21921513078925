const dashboardHelpers = {
  // header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent in placerat arcu. Fusce vehicula, lacus fermentum egestas viverra, risus libero scelerisque ex, sit amet fermentum orci nunc pellentesque felis.',
  data: [{
    title: 'Início da Obra',
    text: 'Data da primeira tarefa executada de acordo com o cronograma'
  }, {
    title: 'Previsão da Obra',
    text: 'Última data prevista de acordo com o cronograma Base'
  }, {
    title: 'CUB - Residencial',
    text: 'Valor do CUB de referência da obra para o mês vigente'
  }, {
    title: 'Área Construída',
    text: 'Metragem total da obra de acordo com o projeto arquitetônico'
  }, {
    title: 'Orçado Indexado/Tendência',
    text: 'Valor do Orçamento Indexado dividido pelo valor da Tendência da obra'
  }, {
    title: 'IDP',
    text: 'Índice de Desempenho de Prazo, calculado a partir da divisão do percentual medido acumulado pelo percentual acumulado da linha Base até a data da medição'
  }, {
    title: 'IDC',
    text: 'Índice de Desempenho de Custo, calculado a partir da divisão do valor total Agregado (medido) pelo valor total Pago até a data da atualização dos dados'
  }, {
    title: 'Percentual Apropriado',
    text: '(valor pago - itens sem apropriação) / valor pago'
  }, {
    title: 'Orçamento Base',
    text: 'Valor do orçamento da obra no ERP de acordo com a data Base (mês onde foram tomados os preços unitários)'
  }, {
    title: 'Orçamento Indexado',
    text: 'O cálculo tem o objetivo fazer a correção do Orçamento Base no tempo. A correção é feita somando o Orçamento Base com a multiplicação do Orçamento Base pela variação de um indexador (neste caso o CUB) no período transcorrido entre o mês base do orçamento e o mês da última medição'
  }, {
    title: 'Total Comprometido',
    text: 'Valor total comprometido para a obra somando todos os itens pagos e a pagar'
  }, {
    title: 'Verba Disponível',
    text: 'Resultado da subtração do Total Comprometido do Orçamento Base'
  }, {
    title: 'Base Acumulada',
    text: 'Valor percentual acumulado pela linha Base até a data da medição'
  }, {
    title: 'Agregado Acumulado',
    text: 'Valor percentual agregado acumulado até a data da medição'
  }, {
    title: 'Diferença Base-Agregado',
    text: 'Diferença percentual entre a linha Base acumulada e o valor Agregado acumulado na data da última medição'
  }, {
    title: 'Tendência',
    text: 'Estimativa de custo para a obra de acordo com o padrão de custo realizado.'
  }]
}

const valueHelpers = {
  // header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent in placerat arcu. Fusce vehicula, lacus fermentum egestas viverra, risus libero scelerisque ex, sit amet fermentum orci nunc pellentesque felis.',
  data: [{
    title: 'Base',
    text: 'Valor agregado previsto pela linha Base do cronograma'
  }, {
    title: 'Valor Previsto',
    text: 'Valor agregado previsto pelo cronograma atual da obra'
  }, {
    title: 'Valor Agregado',
    text: 'Valor agregado a obra de acordo com o custo orçado das tarefas que foram medidas no cronograma'
  }, {
    title: 'Valor Pago',
    text: 'Somatório de todas as saídas de caixa até a data da medição'
  },
  ]
}

const projectionHelpers = {
  // header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent in placerat arcu. Fusce vehicula, lacus fermentum egestas viverra, risus libero scelerisque ex, sit amet fermentum orci nunc pellentesque felis.',
  data: [{
    title: 'Desembolso',
    text: 'Previsão de custo para cada mês utilizando como referência o custo orçado para cada tarefa a ser executada, adicionando a opção de cadastramento do meio de pagamento de cada insumo planejado'
  }, {
    title: 'A pagar',
    text: 'Valor previsto para pagamento nos meses futuros de acordo com as previsões de pedidos e contratos'
  }
  ]
}

export const HelpModalText = (path) => {
  switch (path) {
    case 'valor-agregado':
      return valueHelpers;
    case 'projecao-financeira':
      return projectionHelpers;
    default:
      return dashboardHelpers;
  }
}