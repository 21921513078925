import { useDashboardUIActions, useDashboardUIContext } from "@contexts/dashboardUI";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { isBuildingTrial } from "@utils/checkPermiossion";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CostsBarChart = () => {

  const tableMock = [{
    title: 'Títuilo',
    total: 864,
  }, {
    title: 'Títuilo',
    total: 185,
  }, {
    title: 'Títuilo',
    total: 123
  }, {
    title: 'Títuilo',
    total: 234,
  }, {
    title: 'Títuilo',
    total: 512,
  }, {
    title: 'Títuilo',
    total: 124,
  }, {
    title: 'Títuilo',
    total: 864,
  }, {
    title: 'Títuilo',
    total: 185,
  }, {
    title: 'Títuilo',
    total: 123
  }, {
    title: 'Títuilo',
    total: 234,
  }, {
    title: 'Títuilo',
    total: 512,
  }, {
    title: 'Títuilo',
    total: 124,
  }]


  return <Bar options={{
    scales: {
      x: {
        display: true,
      },
      y: {
        display: false,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 1|3,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Custo por Etapa",
        align: "start",
        color: "black",
        font: {
          size: "22px",
          position: 'center'
        }
      }
    }
  }}
    data={{
      labels: tableMock.map(i => `${i.title}: ${i.total}`),
      datasets: [
        {
          label: "",
          borderColor: "#6852F2",
          backgroundColor: "#6852F2",
          data: tableMock.map(i => i.total),
        },
      ]
    }} />
}

export default CostsBarChart;