import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import SubstepAccordion from "./SubstepAccordion";
import AccordionItem from "./AccordionItem";

const StepAccordion = ({parent, filters}) => {
  
  const {activeProject: {_id, steps = {}}} = useProjectContext();
  const { loadSteps } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadSteps(parent);
    } 
  },[_id])

  const cellSteps = useMemo(() => steps[parent], [steps, parent])

  return (
    <>
      {!cellSteps && <LoaderItem />}
      {cellSteps?.map((item, index) => <AccordionItem key={index} item={item} type={"step"} typeTitle={"Etapa"} ChildAccordionComponent={SubstepAccordion} filters={filters}/> )}
    </>
  )
}

export default StepAccordion;