import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { useProjectContext } from "@contexts/projects";
import { useMemo } from "react";
import { ensureDateType, formatDateToNumberMMYY } from "@utils/formatDate";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)


const ProjectLineChart = () => {
  const { activeProject: { followUps } } = useProjectContext();

  const { measured, baseline, scheduled, dates } = useMemo(() => followUps.reduce((accumulator, followUp) => {
    accumulator.measured.push(followUp.measured ? followUp.measured * 100 : undefined)
    accumulator.baseline.push(followUp.baseline ? followUp.baseline * 100 : undefined)
    accumulator.scheduled.push(followUp.scheduled ? followUp.scheduled * 100 : undefined)
    accumulator.dates.push(formatDateToNumberMMYY(followUp.date))
    return accumulator
  }, {
    measured: [],
    baseline: [],
    scheduled: [],
    dates: [],
  }), [followUps])

  return (
    <Line options={{
      responsive: true,
      maintainAspectRatio: false,
      // aspectRatio: 1|3,
      height: 500,
      plugins: {
        legend: {
          display: true,
          position: "top",
          align: "center",
        },
        datalabels: {
          display: false
        },
        title: {
          display: false,
          text: "Avanço Físico Financeiro",
          align: "start",
          color: "black",
          font: {
            size: "18px"
          }
        }
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: 'Mês/Ano',
            font: {
              weight: 'bold'
            }
          }
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Porcentagem',
            font: {
              weight: 'bold'
            }
          }
        }
      }
    }}
      data={{
        labels: dates,
        datasets: [
          {
            label: "Agregado",
            borderColor: "#3acff7",
            backgroundColor: "#3acff7",
            data: measured,
          },
          // {
          //   label: "Base",
          //   borderColor: "#6852F2",
          //   backgroundColor: "#6852F2",
          //   data: baseline,
          // },
          {
            label: "Previsto",
            borderColor: "#000000",
            backgroundColor: "#000000",
            data: scheduled,
          },
        ]
      }} />
  )
}

export default ProjectLineChart;