import { useState } from "react";

const Tooltip = ({ small, padding, message }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div
        className="tooltip-trigger"
      >
        <p className="open-helper-button question">?</p>
      </div>
      {(message && showTooltip) && <div className="tooltip-box">{message}</div>}
    </div>
  );
};


export default Tooltip