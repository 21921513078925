import { useDashboardUIActions, useDashboardUIContext } from "@contexts/dashboardUI";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { isBuildingTrial } from "@utils/checkPermiossion";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";
import { useEffect, useMemo } from "react";
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const CostsDoughChart = ({ labels, values }) => {

  const tableMock = [{
    title: 'Vermelho',
    total: 864,
  }, {
    title: 'Amarelo',
    total: 185,
  }]


  return <Doughnut options={{
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 1|3,
    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: false,
        text: "Custo por Etapa",
        align: "start",
        color: "black",
        font: {
          size: "22px",
          position: 'center'
        }
      },
    }
  }}
    data={{
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
          ],
        },
      ]
    }} />
}

export default CostsDoughChart;