const UpdateValues = ({ extraValue, setExtraValue, handleUpdateExtra, updatedExtra, stock, setStock, handleUpdateStock, updatedStock, calcStock }) => {
  return <>
    <div className="update-extra-container">
      <h3>Atualizar Valor Não Lançado:</h3>
      <div className="extra-value-container">
        <input placeholder="Valor" value={extraValue?.value} onChange={(e) => setExtraValue({ ...extraValue, value: e.target.value.replace(/[a-zA-Z]/g, '') })} />
        <input className="full" placeholder="Descrição" value={extraValue?.description} onChange={(e) => setExtraValue({ ...extraValue, description: e.target.value })} />
        <button onClick={handleUpdateExtra} className="button button-accent margin-bottom-8">Enviar</button>
      </div>
      {updatedExtra !== "" && (
        updatedExtra === 200 ? <span className="bold">Enviado!</span> : <p>Erro interno, tente novamente em breve.</p>
      )}
    </div>
    <div className="update-extra-container">
      <h3>Atualizar Valor de Estoque:</h3>
      <div className="extra-value-container">
        <input placeholder="Valor" value={stock?.value} onChange={(e) => setStock({ ...stock, value: e.target.value.replace(/[a-zA-Z]/g, '') })} />
        <input className="full" placeholder="Descrição" value={stock?.description} onChange={(e) => setStock({ ...stock, description: e.target.value })} />
        <button onClick={handleUpdateStock} className="button button-accent margin-bottom-8">Enviar</button>
      </div>
      <p className="auto-stock" onClick={calcStock}>Calcular valor automaticamente (Pago - Medido)?</p>
      {updatedStock !== "" && (
        updatedStock === 200 ? <span className="bold">Atualizado!</span> : <p>Erro interno, tente novamente em breve.</p>
      )}
    </div>
  </>
}

export default UpdateValues;