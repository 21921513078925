
import { useCostsActions, useCostsContext } from "@contexts/costs";
// import { itemGroups, measurementUnits } from ".utils";
import { useDashboardUIActions } from "@contexts/dashboardUI";
import { useEffect, useState } from "react";
import { indexes, itemGroups, measurementUnits } from "./components/utils";
import Tooltip from "@components/dashboard/tooltip";
import { formatNumberToCurrency } from "@utils/formatNumber";

const defaultItem = {
  description: '',
  unitValue: '',
  type: '',
  qty: 0,
  total: 0,
  measurement: '',
  observation: '',
  id: 0,
}




const Items = () => {
  const { getBuildingItems, updateBuildingItem, getParams, getMeasurementUnits, selectBuilding, getUnitValues } = useCostsActions();
  const { currentBuilding } = useCostsContext();
  const [itemInfo, setItemInfo] = useState(defaultItem)
  const [items, setItems] = useState([])
  const [chosenType, setChosenType] = useState('all')
  const [params, setParams] = useState({})
  const [chosenParam, setChosenParam] = useState({})
  const [mode, setMode] = useState('manual')
  const [units, setUnits] = useState([])
  const [unitValues, setUnitValues] = useState([])

  useEffect(() => {
    selectBuilding(currentBuilding)
  }, [currentBuilding])

  const getUnits = async () => {
    const uns = await getMeasurementUnits()
    setUnits(uns.res)
  }

  const listItems = async () => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)

    const items = await getBuildingItems(chosenType, currentBuilding._id)
    setItems(items.res)

    const params = await getParams(chosenType)
    setParams(params.res)

    const uns = await getUnitValues()
    setUnitValues(uns.res)

    setIsLoading(false)
  }

  useEffect(() => {
    getUnits()
  }, [])

  useEffect(() => {
    listItems()
  }, [chosenType, currentBuilding])

  const handleSend = async () => {
    const newItem = { ...itemInfo, total: itemInfo.unitValue * itemInfo.qty, type: chosenType, buildingId: currentBuilding._id, id: itemInfo._id }

    const res = await updateBuildingItem(newItem)
  }



  const handleEdit = (event) => {
    event.preventDefault();

    if (event.target.name && mode === 'param') return

    setItemInfo(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  }

  const handleItemEdit = (i) => {
    setItemInfo(items[i])
  }

  const reset = () => {
    setItemInfo(defaultItem)
  }

  const handleChoseType = (e) => {
    setChosenType(e.target.value)
  }

  const setParamValues = (e) => {
    setChosenParam(params[e.target.value])
    const paramField = params[e.target.value].index
    let value;
    if (indexes.map(i => i.label).includes(params[e.target.value].index)) {
      value = currentBuilding[indexes.filter(i => i.label === paramField)[0].field]
    } else {
      value = currentBuilding.custom.filter(i => i.field === paramField)[0].field
    }
    setItemInfo(prev => ({
      ...prev,
      unitValue: unitValues.filter(i => i.paramId === params[e.target.value].name)[0]?.value ?? 0,
      measurement: params[e.target.value].unit,
      qty: value * params[e.target.value].value
    }))
  }

  const getLabel = () => {
    let res;
    if (!chosenParam.name) {
      res = 'Escolha um parametro para ver os detalhes.'
    } else {
      res = `Detalhes do parâmetro ${chosenParam.name}\n Índice: ${chosenParam.index} \n Valor: ${chosenParam.value}`
    }
    return res;
  }

  return <div className="costs">
    <h1>{currentBuilding.name}</h1>
    <select onChange={handleChoseType}>
      <option value={'all'}>Todos</option>
      {itemGroups.map(i => {
        return <option>{i}</option>
      })}
    </select>
    <div className="flex flex-end">
      <button onClick={reset} className="button button-secondary">Novo Item</button>
    </div>
    <div className="grid columns-6-tablet gap-8 margin-bottom-16 align-items-end flex-end">
      <div>
        <label>Descrição</label>
        <input type="text" onChange={handleEdit} value={itemInfo.description} name="description" />
      </div>
      <div>
        <label>Quantitativo</label>
        <select onChange={(e) => setMode(e.target.value)}>
          <option value='manual'>Manual</option>
          <option value='param'>Parâmetros</option>
        </select>
      </div>
      {mode === 'param' && <div>
        <div className="flex">
          <label>Parâmetro</label>
          <Tooltip message={getLabel()} />
        </div>
        <select defaultValue='' onChange={setParamValues}>
          <option disabled value=''>Escolha um parâmetro</option>
          {params.map((i, index) => {
            return <option value={index}>{i.name}</option>
          })}
        </select>
      </div>}
      <div>
        <label>Quantidade</label>
        <input type="number" onChange={handleEdit} value={itemInfo.qty} name="qty" />
      </div>
      <div>
        <label>Valor Unitário</label>
        {mode === 'param' ?
          <select type="number" defaultValue={''} onChange={handleEdit} value={itemInfo.unitValue} name="unitValue">
            <option disabled value=''>Escolha um valor</option>
            {unitValues.filter(i => i.paramId === chosenParam.name).map(i => {
              return <option value={i.value}>{formatNumberToCurrency(i.value)} - {i.reference} - {i.date}</option>
            })}
          </select>
          :
          <input type="number" onChange={handleEdit} value={itemInfo.unitValue} name="unitValue" />}
      </div>
      <div>
        <label>Unidade</label>
        <select name="measurement" onChange={handleEdit} value={itemInfo.measurement}>
          <option disabled value=''>Escolha uma medida</option>
          {units.map(i => {
            return <option value={i.name}>{i.name}</option>
          })}
        </select>
      </div>
      <div>
        <label>Valor Total</label>
        <input type="number" onChange={handleEdit} value={itemInfo.unitValue * itemInfo.qty} name="totalValue" />
      </div>
      <div className="column-span-5">
        <label>Observação</label>
        <input type="text" onChange={handleEdit} value={itemInfo.observation} name="observation" />
      </div>
      <button onClick={handleSend} className="button button-accent justify-self-center">Enviar</button>
    </div>
    <div className="margin-top-16">
      {items.map((i, index) => {
        return <div className="grid columns-7-tablet gap-8 align-center">
          <p className="column-span-2">{chosenType === 'all' && `${i.type} - `} {i.description} {i.observation && <Tooltip message={i.observation} />}</p>
          <p>{formatNumberToCurrency(+i.unitValue ?? 0)}</p>
          <p>{i.qty}</p>
          <p>{i.measurement}</p>
          <p>{formatNumberToCurrency(+i.total ?? 0)}</p>
          <button onClick={() => handleItemEdit(index)} className="button button-secondary">Editar</button>
        </div>
      })}
    </div>
  </div>
}

export default Items