import { useProjectContext } from "@contexts/projects";

export const formatNumberToCurrency = (value = 0, locale = "pt-BR", currency = "BRL") => {
  let val = value
  if (isNaN(val)) return 0
  return Number(val?.toFixed(2).replace('-0', '0')).toLocaleString(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const formatNumberToLocale = (value = 0, locale = "pt-BR") => {
  return roundValue(value).toLocaleString(locale, {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const roundValue = (value = 0) => Math.round(value * 100) / 100;

export const formatToPercentage = (value = 0) => {
  let res = (value * 100).toFixed(2)
  if (isNaN(res)) return 0
  return res
}

export const checkIfCubBased = (value) => {
  const { activeProject: { originalIndexer }, cubBased } = useProjectContext();

  if (cubBased) {
    return value / (originalIndexer?.value ?? 1)
  }
  return value

}

export const checkLabel = () => {
  const { cubBased } = useProjectContext();
  if (cubBased) {
    return 'CUB'
  }
  return 'R$'
}