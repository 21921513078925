import state from "./state.js";
import actions from "./actions.js";
import reducer from "./reducer.js";
import createContext from "../createContext.js";
import { useContext } from "react";

const {
  Context: authContext,
  Provider: AuthProvider,
  actions: processedActions,
} = createContext(state, actions, reducer);

export const useAuthContext = () => {
  return useContext(authContext);
};

export const useAuthActions = () => {
  return processedActions();
};

export default AuthProvider;
