import Card from "@components/Card";
import DateRangeFilter from "@components/dashboard/DateRangeFilter";
import { useProjectContext } from "@contexts/projects";
import { cardTexts } from "@utils/cardTexts";
import { checkIfCubBased, checkLabel, formatNumberToCurrency, formatNumberToLocale } from "@utils/formatNumber";
import { useMemo } from "react";

const Agregator = () => {
  const { activeProject: { predicted, measured, budget, lastMeasure, paidValue } } = useProjectContext();

  return (
    <div className="grid gap-24 columns-5-laptop margin-top-40">
      <div className="column-span-5">
        <hgroup className="dashboard-title flex wrap-wrap gap-16 align-center justify-content-space-between">
          <h2>Valor agregado</h2>
        </hgroup>
      </div>
      <div className="column-span-5">
        <div className="grid columns-2-tablet columns-4-laptop-l gap-16">
          {/* <Card label='Baseline' content={formatNumberToLocale(budget.value * (lastMeasure ? lastMeasure.baseline_accumulated_percentage : 0))} tooltip={cardTexts.BASE} /> */}

          <Card label={`Valor previsto (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(predicted?.value))} tooltip={cardTexts.EXPECTED} />

          <Card label={`Valor medido (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(measured?.value))} />

          <Card label={`Valor pago (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(paidValue))} />

        </div>
      </div>
    </div>
  )
};

export default Agregator;
