import { Link } from "react-router-dom";

import Loader from "@components/Loader";

const PasswordResetRequest = () => {
  const isLoading = false;

  return (
    <form id="access-form">
      {isLoading && <Loader />}

      <hgroup>
        <h1>Recuperar senha</h1>
        <h2 className="margin-top-16">
          Para recuperar sua senha, insira o e-mail cadastrado no campo abaixo.
        </h2>
      </hgroup>
      <fieldset className="grid gap-16 margin-top-32">
        <label>
          <span>Insira seu e-mail</span>
          <input type="email" value={""} onChange={() => {}} required={true} />
        </label>
      </fieldset>
      <div className="flex align-center justify-content-space-between gap-16 wrap-wrap margin-top-32">
        <Link to="/access" className="text-small">
          Voltar
        </Link>
        <button className="button button-accent">Continuar</button>
      </div>
    </form>
  );
};

export default PasswordResetRequest;
