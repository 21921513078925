import { useDashboardUIActions } from "@contexts/dashboardUI";
import { useProjectActions } from "@contexts/projects";
import { getIndicatorColor } from "@utils/colorCalculations";
import { checkIfCubBased, formatNumberToCurrency, formatNumberToLocale } from "@utils/formatNumber";
import CircularBar from "../CircularBar";
import { useProjectContext } from "@contexts/projects";
import { isBuildingTrial } from "@utils/checkPermiossion";

const { useState, useCallback, useMemo } = require("react")

const AccordionItem = ({ item, type, typeTitle, ChildAccordionComponent, filters }) => {
  const { toggleItemDetailOffcanvas } = useDashboardUIActions();
  const { selectDetail } = useProjectActions();
  const { activeProject } = useProjectContext();
  const { first, second, third, fourth } = filters

  const [toggle, setToggle] = useState(false)
  const onShowDetail = useCallback(() => {
    selectDetail(item, type)
    toggleItemDetailOffcanvas()
  }, [item, type])

  const color = useMemo(() => getIndicatorColor(item.idc), [item.idc])

  item.stockValue = item.stock?.value

  const getField = (field) => {
    let res;
    switch (field) {
      case 'commited':
        res = 'Comprometido'
        break;
      case 'extraValue':
        res = 'Não Lançado'
        break;
      case 'commited_extra':
        res = 'Comprometido + Não Lançado'
        break;
      case 'budget_minus_paid':
        res = 'Saldo'
        break;
      case 'indexed_balance':
        res = 'Saldo Indexado'
        break;
      case 'accumulated':
        res = 'R. Acumulado'
        break;
      case 'stockValue':
        res = 'Em Estoque'
        break;
      case 'idc':
        res = 'IDC'
        break;
      case 'trend':
        res = 'Tendência'
        break;
      case 'stockIdc':
        res = 'IDC com Estoque'
        break;
      case 'stockTrend':
        res = 'Tendência com Estoque'
        break;
      case 'paid':
        res = 'Pago'
        break;
      case 'paidStock':
        res = 'Pago - Estoque'
        break;

      default:
        break;
    }
    return res
  }

  return (
    <div className="accordion-item">
      <div className="accordion-item-body">
        <div>
          <div className="flex align-center gap-8">
            {ChildAccordionComponent && (
              <button
                className={`accordion-toggle ${toggle ? "active" : ""}`}
                onClick={() => setToggle(!toggle)}
              ></button>
            )}
            <div className="grid">
              <span className="accordion-label">EAP #{item.building_unit_id}{item.wbs ? `.${item.wbs}` : ""} / {typeTitle}</span>
              <span className="accordion-title">{item.description ?? item.building_unit_name}</span>
            </div>
          </div>
        </div>
        <div className="column-span-2-tablet">
          <div className="flex wrap-wrap wrap-nowrap-tablet gap-16 align-center">
            <div className="accordion-content columns-6-tablet">
              <div className="data">
                <span className="label">Orçado {first === 'budget' ? '' : 'Indexado'}</span>
                <span className="value">{formatNumberToLocale(checkIfCubBased(item[first] ?? 0))}</span>
              </div>
              {!isBuildingTrial() &&
                <>
                  <div className="data">
                    <span className="label">Previsto</span>
                    <span className="value">{formatNumberToLocale(checkIfCubBased(item.predicted ?? 0))}</span>
                  </div>
                  <div className="flex gap-8">
                    <CircularBar value={Math.round(+((item.measured / item.budget) * 100))} />
                    <div className="data">
                      <span className="label align-self-end margin-right-16">Medido</span>
                      <span className="value">{formatNumberToLocale(checkIfCubBased(item.measured ?? 0))}</span>
                    </div>
                  </div>
                  <div className="data">
                    <span className="label">{getField(fourth)}</span>
                    <span className="value">{formatNumberToLocale(checkIfCubBased((fourth === 'paid' ? item.paid : (item.paid - item.stockValue) ?? 0)))}</span>
                  </div>
                  <div className="data">
                    <span className="label">{getField(second)}</span>
                    <span className={`value`}>{formatNumberToLocale(checkIfCubBased(second === 'commited_extra' ? ((item.extraValue.value + item.commited) ?? 0) : (second === "extraValue" ? item.extraValue.value : item[second] ?? 0)))}</span>
                  </div>
                  <div className="data">
                    <span className="label">{getField(third)}</span>
                    <span className={`value ${third.toLowerCase().includes('idc') && `color-${color}`}`}>{formatNumberToLocale(checkIfCubBased(item[third] ?? 0))}</span>
                  </div>
                </>
              }
            </div>
            <div className="accordion-actions">
              <button className="toggle-offcanvas" onClick={onShowDetail}>
                <i
                  className="icon icon-width-16 icon-ellipsis-h"
                  aria-hidden={true}
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {toggle && <ChildAccordionComponent parent={item.wbs_code_id} filters={filters} />}
    </div>
  )
}

export default AccordionItem;