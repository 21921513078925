import { Outlet } from "react-router-dom";

import Loader from "@components/Loader";

import Logo from "@assets/images/ampli-logo.svg";

const Access = () => {
  const isLoading = false;

  return (
    <section id="access">
      {isLoading && <Loader />}
      
      <div className="container">
        <div className="grid gap-48 columns-2-laptop align-center justify-center">
          <div>
            <figure id="access-logo">
              <img src={Logo} alt="Ampli" />
            </figure>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Access;
