import { useState } from "react";
import Tooltip from "./dashboard/tooltip";

const Card = ({ label, content, subcontent, tooltip = '', color = '' }) => {

  return <div className="card">
    <div className="flex justify-content-space-between">
      <span className="card-title">{label}</span>
      {tooltip !== '' && <Tooltip message={tooltip} />}
    </div>
    <span className={`card-value color-${color}`}>{content ?? 0}</span>
    {subcontent && <span className="card-label">{subcontent}</span>}


  </div>
}

export default Card;