import UserRow from "@components/dashboard/backoffice/userRow";
import Modal from "@components/Modal";
import { useAuthActions } from "@contexts/auth";
import { useProjectActions } from "@contexts/projects";
import { useEffect, useState } from "react";

const Users = () => {
  const [users, setUsers] = useState([])
  const [companies, setCompanies] = useState([])
  const [buildings, setBuildings] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [resetModalVisible, setResetModalVisible] = useState(false)
  const { listUsers, updateUser, resetPassword } = useAuthActions();
  const { listCompanies } = useProjectActions();

  const getCompanies = async () => {
    const res = await listCompanies();
    setCompanies(res.companies)
    setBuildings(res.buildings)
  }

  const getUsers = async () => {
    const res = await listUsers();
    setUsers(res.users)
  }

  useEffect(() => {
    getUsers();
    getCompanies();
  }, [])

  const handleEdit = (i) => {
    setSelectedUser(i)
    setEditModalVisible(!editModalVisible)
  }

  const handleReset = (i) => {
    setSelectedUser(i)
    setResetModalVisible(!editModalVisible)
  }

  const handleTypeChange = (e) => {
    setSelectedUser((prev) => ({ ...prev, type: e.target.value }))
  }

  const handleCompany = (e) => {
    const { value } = e.target;
    const userCompanies = selectedUser.companyId;
    if (!userCompanies.map(i => i._id).includes(value)) {
      setSelectedUser(prev => ({
        ...prev,
        companyId: [...userCompanies, { _id: value }]
      }));
    } else {
      const updatedCompanies = userCompanies.filter(i => i._id !== value);
      const updatedBuildings = selectedUser.buildingIds.filter(buildingId => {
        const building = buildings.find(b => b._id === buildingId);
        return building && building.company_id !== value;
      });
      setSelectedUser(prev => ({
        ...prev,
        companyId: updatedCompanies,
        buildingIds: updatedBuildings
      }));
    }
  }

  const handleSend = async () => {
    await updateUser({ _id: selectedUser._id, companyId: selectedUser.companyId, type: selectedUser.type, buildingIds: selectedUser.buildingIds })
    // location.reload()
  }

  const confirmReset = async () => {
    const response = await resetPassword({ email: selectedUser.email })
  }

  const handleBuilding = (e) => {
    const { value } = e.target;
    const userBuildings = selectedUser.buildingIds;
    if (!userBuildings.includes(value)) {
      setSelectedUser(prev => ({
        ...prev,
        buildingIds: [...userBuildings, value]
      }));
    } else {
      const updatedBuildings = userBuildings.filter(i => i !== value);
      setSelectedUser(prev => ({
        ...prev,
        buildingIds: updatedBuildings
      }));
    }
  }

  return (
    <>
      {editModalVisible && <Modal close={() => setEditModalVisible(false)}>
        <div className="edit-user-modal">
          <label>Tipo</label>
          <select value={selectedUser.type} name="type" onChange={handleTypeChange}>
            <option value='admin'>Admin</option>
            <option value='user'>User</option>
          </select>
          <h2>Construtoras</h2>
          <div className="companies grid gap-16 columns-4-tablet align-center">
            {companies.map(i => {
              return <div key={`${i._id}`}>
                <input onChange={handleCompany} checked={selectedUser.companyId.map(i => i._id).includes(i._id)} type="checkbox" value={`${i._id}`} />
                <label>{i.name}</label>
              </div>
            })}
          </div>
          <h2>Obras</h2>
          <div className="companies grid gap-16 columns-4-tablet align-center">
            {buildings.filter(i => selectedUser.companyId.map(i => i._id).includes(i.company_id)).map(i => {
              return <div key={`${i._id}`}>
                <input onChange={handleBuilding} checked={selectedUser.buildingIds.includes(i._id)} type="checkbox" value={`${i._id}`} />
                <label>{i.building_name}</label>
              </div>
            })}
          </div>
        </div>
        <button className="button button-accent" onClick={handleSend}>Enviar</button>
      </Modal>}
      {resetModalVisible && <Modal close={() => setResetModalVisible(false)}>
        <h1>Resetar a senha da conta {selectedUser.name}?</h1>
        <button className="button button-accent" onClick={confirmReset}>Resetar</button>
      </Modal>}
      <div>
        {users.map(i => {
          return <UserRow user={i} key={i._id} edit={() => handleEdit(i)} reset={() => handleReset(i)} />
        })}
      </div>
    </>
  );
};

export default Users;
