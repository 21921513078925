export default (state, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        showSidebar: !state.showSidebar
      }
    case "TOGGLE_OFFCANVAS":
      return {
        ...state,
        showOffcanvas: !state.showOffcanvas
      }
    case "TOGGLE_ITEM_DETAIL_OFFCANVAS":
      return {
        ...state,
        showItemDetailOffcanvas: !state.showItemDetailOffcanvas
      }
    case "TOGGLE_HELP_MODAL":
      return {
        ...state,
        showHelpModal: !state.showHelpModal
      }
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.data
      }
    case "SET_BAR_CHART_DATE":
      return {
        ...state,
        barChartDate: action.data
      }
    default:
      return state;
  }
};
