import { useEffect } from "react";
import LoaderItem from "./LoaderItem";
import AccordionItem from "./AccordionItem";
import CellAccordion from "./CellAccordion";
import { useDashboardUIContext } from "@contexts/dashboardUI";

const { useProjectContext, useProjectActions } = require("@contexts/projects")

const UnitAccordion = () => {
  const { barChartDate } = useDashboardUIContext()
  const { activeProject: { _id, units }, filterDates } = useProjectContext();
  const { loadUnits } = useProjectActions();

  useEffect(() => {
    if (_id) {
      loadUnits(barChartDate);
    }
  }, [_id, filterDates, barChartDate])

  return (
    <div className="accordion margin-top-24">
      {!units && (

        <LoaderItem />

      )}
      {units?.map((item, index) => <AccordionItem key={index} item={item} type={"unit"} typeTitle={"Unidade construtiva"} ChildAccordionComponent={item.building_unit_name === 'ITENS SEM APROPRIAÇÃO' ? null : CellAccordion} />)}
    </div>
  )
}

export default UnitAccordion;