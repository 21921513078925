const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <p className="text-xsmall color-light">
        © {currentYear} Ampli. Todos os direitos reservados.
      </p>
    </footer>
  );
};

export default Footer;
