import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import AccordionItem from "./AccordionItem";
import StepAccordion from "./StepAccordion";

const CellAccordion = ({parent, filters}) => {
  
  const {activeProject: {_id, cells = {}}} = useProjectContext();
  const { loadCells } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadCells(parent);
    } 
  },[_id])

  const unitCells = useMemo(() => cells[parent], [cells, parent])

  return (
    <>
      {!unitCells && <LoaderItem />}
      {unitCells?.map((item, index) => <AccordionItem key={index} item={item} type={"cell"} typeTitle={"Célula construtiva"} ChildAccordionComponent={StepAccordion} filters={filters}/>)}
    </>
  )
}

export default CellAccordion;