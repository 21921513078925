import {
  useDashboardUIActions,
  useDashboardUIContext,
} from "@contexts/dashboardUI";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { useState, useMemo, useEffect } from "react";
import LoaderItem from "./value/accordion/LoaderItem";
import CreditorAccordion from "./offcanvas/contract/creditorAccordion";
import { isBuildingTrial } from "@utils/checkPermiossion";
import ContractAccordion from "./offcanvas/contract/contractAccordion";
import ResourceAccordion from "./offcanvas/resourceAccordion";
import UpdateValues from "./offcanvas/updateValues";
import Pagination from "./offcanvas/pagination";
import MainCards from "./offcanvas/mainCards";
import EapDisb from "./offcanvas/curve/eapDisb";
import EapResources from "./offcanvas/curve/eapDivisions";
import EapLevels from "./offcanvas/curve/eapLevels";

const ItemDetailOffcanvas = () => {
  const { selectedDetail: { item, type }, activeProject, resourceEAP } = useProjectContext();
  const { getResources, getCreditors, getContracts, updateExtra, updateStock, updateProjectLive } = useProjectActions();
  const { showItemDetailOffcanvas, barChartDate } = useDashboardUIContext();
  const { toggleItemDetailOffcanvas } = useDashboardUIActions();
  const [resources, setResources] = useState({ items: [], totalPages: 1 })
  const [toPay, setToPay] = useState(false)
  const [tabView, setTabView] = useState('resources')
  const [seeEAP, setSeeEAP] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [creditorOnly, setCreditorOnly] = useState(false)
  const [extraValue, setExtraValue] = useState({
    value: 0,
    description: ''
  })
  const [updatedExtra, setUpdatedExtra] = useState("")
  const [updatedStock, setUpdatedStock] = useState("")
  const [stock, setStock] = useState({
    value: 0,
    description: ''
  })
  const [resourceOrder, setResourceOrder] = useState('evaluated')

  const filterResources = async () => {
    setLoading(true)
    let response;
    if (tabView === 'creditors') {
      const limitDate = window.location.pathname.includes('projecao-financeira')
      response = await getCreditors(type, item?.wbs_code_id, currentPage, barChartDate, limitDate, toPay)
    } else if (tabView === 'contracts') {
      response = await getContracts(type, item?.wbs_code_id)
    } else {
      response = await getResources(item?.wbs_code_id, currentPage, resourceOrder)
    }
    setResources({ ...resources, items: response.items, totalPages: response.totalPages, itemsCount: response.totalItemsCount })
    setLoading(false)
  }

  useEffect(() => {
    setCurrentPage(1)
    setExtraValue({ value: item?.extraValue?.value ?? 0, description: item?.extraValue?.description ?? "" })
    setStock({ value: item?.stock?.value ?? 0, description: item?.stock?.description ?? "" })
  }, [type, item, tabView, toPay, resourceOrder])

  useEffect(() => {
    filterResources()
    return () => {
      setResources([])
      setUpdatedExtra('')
      setUpdatedStock('')
    }
  }, [type, item, currentPage, tabView, toPay, resourceOrder])

  useEffect(() => {
    if (window.location.pathname.includes('projecao-financeira')) {
      setCreditorOnly(true)
      setTabView('creditors')
    } if (window.location.pathname.includes('insumos') || window.location.pathname.includes('curva')) {
      setSeeEAP(true)
      setCreditorOnly(true)
    }
    return () => {
      setSeeEAP(false)
      setCreditorOnly(false)
    }

  }, [window.location.pathname, activeProject])

  const goBack = () => {
    if (currentPage === 1) return;

    setCurrentPage(prev => prev - 1)
  }

  const goForward = () => {
    if (currentPage === resources.totalPages) return;

    setCurrentPage(prev => prev + 1)
  }

  const typeTitle = useMemo(() => {
    switch (type) {
      case "unit":
        return "Unidade Construtiva";
      case "cell":
        return "Célula Construtiva";
      case "step":
        return "Etapa";
      case "substep":
        return "Subetapa";
      case "service":
        return "Serviço";
      case "resource":
        return "Insumo";
      default:
        return "";
    }
  }, [type])

  if (!item) return;

  const updateLiveValues = (field) => {
    const newValue = field === 'stock' ? stock.value : extraValue.value
    const arrayName = `${type}s`;
    let newArray = [];
    if (type !== 'unit') {
      const oldArray = activeProject[arrayName];
      newArray = Object.fromEntries(
        Object.entries(oldArray).map(([groupKey, array]) => [
          groupKey,
          array.map((i) =>
            i._id === item._id
              ? { ...i, [field]: { ...i[field], value: newValue } }
              : i
          ),
        ])
      );
    } else {
      newArray = activeProject[arrayName].map((i) =>
        i._id === item._id
          ? { ...i, [field]: { ...i[field], value: newValue } }
          : i
      );
    }
    const newProjItem = { ...activeProject, [arrayName]: newArray };
    updateProjectLive(newProjItem);
  };


  const handleUpdateExtra = async () => {
    const update = await updateExtra(item.wbs_code_id, extraValue.value, extraValue.description)
    if (update.code === 200) {
      setUpdatedExtra(200)
      updateLiveValues('extraValue')
    } else {
      setUpdatedExtra(500)
    }
  }

  const handleUpdateStock = async () => {
    const update = await updateStock(item.wbs_code_id, stock.value, stock.description)
    if (update.code === 200) {
      setUpdatedStock(200)
      updateLiveValues('stock')
    } else {
      setUpdatedStock(500)
    }
  }

  const handleResourceOrder = (e) => {
    e.preventDefault()
    setResourceOrder(e.target.value)
  }

  const calcStock = () => {
    const newVal = item.paid - item.measured;
    setStock({ ...updateExtra, value: newVal.toFixed(2) })
  }

  const checkName = () => {
    if (item.description) return item.description
    if (item.building_unit_name) return item.building_unit_name
    if (item.name) return item.name
  }

  return (
    <div className={showItemDetailOffcanvas ? "offcanvas active" : "offcanvas"}>
      <div className="offcanvas-container">
        <hgroup className="offcanvas-header">
          <h2>{`${typeTitle} - ${checkName()}`}</h2>
          <button onClick={toggleItemDetailOffcanvas}>
            <i
              className="icon icon-width-24 icon-times background-accent"
              aria-hidden={true}
            ></i>
          </button>
        </hgroup>
        <div className="offcanvas-content">
          {seeEAP ?
            <>
              <div className="grid gap-8 columns-2-tablet columns-2-laptop-l">
                {resourceEAP?.response && <EapLevels response={resourceEAP.response} />}
                <EapDisb item={item} resourceEAP={resourceEAP} />
              </div>
              <div>
                {resourceEAP?.response &&
                  <EapResources
                    item={item}
                    appropriation_level={activeProject.appropriation_level}
                    divisions={resourceEAP.response}
                  />}
              </div>
            </> : <>
              {!isBuildingTrial() && <MainCards item={item} />}
            </>}
          {!seeEAP && <>
            {type === activeProject.appropriation_level &&
              <UpdateValues
                extraValue={extraValue}
                setExtraValue={setExtraValue}
                updatedExtra={updatedExtra}
                handleUpdateStock={handleUpdateStock}
                handleUpdateExtra={handleUpdateExtra}
                updatedStock={updatedStock}
                stock={stock}
                setStock={setStock}
                calcStock={calcStock}
              />
            }
            <div className="offcanvas-tabs">
              {!isBuildingTrial() && <button onClick={() => setTabView('creditors')} className={tabView === 'creditors' ? 'active' : undefined}>Fornecedores</button>}
              {!creditorOnly && <button onClick={() => setTabView('resources')} className={tabView === 'resources' ? 'active' : undefined}>Insumos</button>}
              {!creditorOnly && <button onClick={() => setTabView('contracts')} className={tabView === 'contracts' ? 'active' : undefined}>Contratos</button>}
            </div>
            <div className="flex space-between gap-16 margin-top-8 offcanvas-buttons">
              {tabView === 'creditors' &&
                <div className="flex gap-8">
                  <button onClick={() => setToPay(false)} className={!toPay ? 'active' : undefined}>Pago</button>
                  <button onClick={() => setToPay(true)} className={toPay ? 'active' : undefined}>A pagar</button>
                </div>
              }
              {tabView === 'resources' &&
                <div className="flex gap-8 align-center">
                  <p className="margin-bottom-8">Ordenar por: </p>
                  <select onChange={handleResourceOrder}>
                    <option value='evaluated'>Total Orçado</option>
                    <option value='attended'>Total Apropriado</option>
                  </select>
                </div>
              }
              {(!loading && tabView !== 'contracts') &&
                <Pagination count={resources.itemsCount} perPage={20} currentPage={currentPage} goBack={goBack} goForward={goForward} />
              }
            </div>
          </>}

          {tabView === 'creditors' &&
            <div className="accordion margin-top-24">
              {resources?.items?.length > 0 && (
                resources?.items.map((i, idx) => {
                  return <CreditorAccordion key={idx} item={i} />
                })
              )}
            </div>
          }
          {tabView === 'resources' &&
            <div className="accordion margin-top-24">
              {resources?.items?.length > 0 && (
                resources?.items?.map((i, idx) => {
                  return <ResourceAccordion resource={i} index={idx} />
                })

              )}
            </div>}
          {tabView === 'contracts' &&
            <div className="accordion margin-top-24">
              {resources?.items?.map((resource, index) => {
                return <ContractAccordion key={index} item={resource} />
              })}
            </div>
          }

          {loading && <div className="margin-top-24"><LoaderItem /></div>}
        </div>
      </div>
      {showItemDetailOffcanvas && <div onClick={toggleItemDetailOffcanvas} className="offcanvas-background" />}
    </div>
  );
};

export default ItemDetailOffcanvas;
