import DateRangeFilter from "@components/dashboard/DateRangeFilter";
import ProjectLineChart from "./ProjectLineChart";
import IndicatorCard from "./IndicatorCard";
import Indicators from "./Indicators";
import { useState } from "react";
import FinancialBarChart from "../financialProjection/FinancialBarChart";

const Forecast = () => {
  const [graph, setGraph] = useState(0)

  return <div className="grid gap-24 columns-5-laptop margin-top-40">
    <div className="column-span-4">
      <hgroup className="dashboard-title flex wrap-wrap gap-16 align-center justify-content-space-between">
        <div className="flex gap-16 graph-labels">
          <h2 className={graph === 0 ? 'active-graph' : ''} onClick={() => setGraph(0)}>Fluxo de Caixa</h2>
          <h2 className={graph === 1 ? 'active-graph' : ''} onClick={() => setGraph(1)}>Avanço físico financeiro</h2>
        </div>
        <DateRangeFilter />
      </hgroup>
    </div>
    <div id="chart-line" className="column-span-5 column-span-4-laptop">
      {graph === 1 ? <ProjectLineChart /> : <FinancialBarChart graphSet={'graphB'} />}
    </div>
    <Indicators />
  </div>
};

export default Forecast;
