import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import AccordionItem from "./AccordionItem";
import StepAccordion from "./StepAccordion";
import { useDashboardUIContext } from "@contexts/dashboardUI";

const CellAccordion = ({parent}) => {
  
  const { barChartDate } = useDashboardUIContext()
  const {activeProject: {_id, cells = {}}, filterDates} = useProjectContext();
  const { loadCells } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadCells(parent, barChartDate);
    } 
  },[_id, filterDates, barChartDate])

  const unitCells = useMemo(() => cells[parent], [cells, parent])

  return (
    <>
      {!unitCells && <LoaderItem />}
      {unitCells?.map((item, index) => <AccordionItem key={index} item={item} type={"cell"} typeTitle={"Célula construtiva"} ChildAccordionComponent={StepAccordion}/>)}
    </>
  )
}

export default CellAccordion;