export const cardTexts = {
  BUILDING_START: 'Data da primeira tarefa executada de acordo com o cronograma',
  BUILDING_PREVISION: 'Última data prevista de acordo com o cronograma Base',
  CUB: 'Valor do CUB de referência da obra para o mês vigente',
  AREA: 'Metragem total da obra de acordo com o projeto arquitetônico',
  TREND_BUDGETED: 'Valor do Orçamento Indexado dividido pelo valor da Tendência da obra',
  IDP: 'Valor do Orçamento Indexado dividido pelo valor da Tendência da obra',
  IDC: 'Índice de Desempenho de Custo, calculado a partir da divisão do valor total Agregado (medido) pelo valor total Pago até a data da atualização dos dados',
  APPROPRIATED: '(valor pago - itens sem apropriação) / valor pago',
  BUDGET_BASE: 'Valor do orçamento da obra no ERP de acordo com a data Base (mês onde foram tomados os preços unitários)',
  BUDGET_INDEXED: 'O cálculo tem o objetivo fazer a correção do Orçamento Base no tempo. A correção é feita somando o Orçamento Base com a multiplicação do Orçamento Base pela variação de um indexador (neste caso o CUB) no período transcorrido entre o mês base do orçamento e o mês da última medição',
  COMMITED_TOTAL: 'Valor total comprometido para a obra somando todos os itens pagos e a pagar',
  AVAILABLE_FUNDS: 'Resultado da subtração do Total Comprometido do Orçamento Base',
  ACCUMULATED_BASE: 'Valor percentual acumulado pela linha Base até a data da medição',
  ACCUMULATED_AGGREGATED: 'Valor percentual agregado acumulado até a data da medição',
  BASE_AGGREGATED_DIF: 'Diferença percentual entre a linha Base acumulada e o valor Agregado acumulado na data da última medição',
  TREND: 'Estimativa de custo para a obra de acordo com o padrão de custo realizado.',
  BASE: 'Valor agregado previsto pela linha Base do cronograma',
  EXPECTED: 'Valor agregado previsto pelo cronograma atual da obra',
  AGGREGATED: 'Valor agregado a obra de acordo com o custo orçado das tarefas que foram medidas no cronograma',
  PAID: 'Somatório de todas as saídas de caixa até a data da medição',
  DISBURSEMENT: 'Previsão de custo para cada mês utilizando como referência o custo orçado para cada tarefa a ser executada, adicionando a opção de cadastramento do meio de pagamento de cada insumo planejado',
  TO_PAY: 'Valor previsto para pagamento nos meses futuros de acordo com as previsões de pedidos e contratos'
}