import state from "./state.js";
import actions from "./actions.js";
import reducer from "./reducer.js";
import createContext from "../createContext.js";
import { useContext } from "react";

const {
  Context: costsContext,
  Provider: CostsProvider,
  actions: processedActions,
} = createContext(state, actions, reducer);

export const useCostsContext = () => {
  return useContext(costsContext);
};

export const useCostsActions = () => {
  return processedActions();
};

export default CostsProvider;
