export const itemGroups = [
  "Movimentação de Terra",
  "Terraplanagem",
  'Contenções',
  'Infraestrutura',
  'Supraestrutura',
  'Alvenaria',
  'Revestimento de Piso',
  'Revestimento de Teto',
  'Acabamentos de Piso',
  'Revestimentos de Parede',
  'Acabamentos de Teto',
  'Pintura Interna',
  'Impermeabilização',
  'Instalações',
  'Sistemas Especiais',
  'Serviços Complementares',
  'Esquadrias de Madeira',
  'Esquadrias Metálicas',
  'Fachada',
  'Imprevistos e Contigencias',
  'Despesas Indiretas',
  'Mão de Obra',
  'Cobertura',
  'Paredes e Painéis'
]

export const measurementUnits = [
  'm²',
  'm³',
  'vb'
]

export const getUniques = (array, field) => {
  return [...new Set(array.map(item => item[field]))];
}

export const getPercentage = (part, total) => ((part / total) * 100).toFixed(2);

export const indexes = [
  { label: 'Área do Terreno', field: 'terrain_area' },
  { label: 'Área Total Construída', field: 'build_area' },
  { label: 'Pavimentos', field: 'total_floors' },
  { label: 'Pavimentos Tipo', field: 'type_floors' },
  { label: 'Pavimentos Diferenciados', field: 'unique_floors' },
  { label: 'Área Pavimentos Diferenciados', field: 'unique_floors_area' },
  { label: 'Duplex', field: 'duplex' },
  { label: 'Projeção da Torre', field: 'tower_projection' },
  { label: 'Área de Lazer Interna', field: 'inside_area' },
  { label: 'Área de Lazer Externa', field: 'outside_area' },
  { label: 'Área de Subsolos', field: 'underground_area' },
  { label: 'Subsolos', field: 'underground_floors' },
  { label: 'Área de Projeção de Embasamento', field: 'basement_area' },
  { label: 'Perímetro de Projeção do Embasamento', field: 'basement_perimeter' },
  { label: 'Número de Pavimentos de Embasamento', field: 'basement_floors' },
  { label: 'Número de Apartamentos', field: 'rooms' },
]
