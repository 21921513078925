import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const DetailsGraph = ({ data, onClick }) => {

  const labels = data.map(i => {
    let m = i.month
    if (i.month < 10) m = `0${i.month}`
    return `${m}/${i.year}`
  })

  return <Bar options={{
    scales: {
      x: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: 'Mês/Ano',
          font: {
            weight: 'bold'
          }
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Valor',
          font: {
            weight: 'bold'
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: onClick,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "Selecione o mês para adiantar valores:",
        align: "start",
        font: {
          size: "18px"
        }
      },
      datalabels: {
        display: true,           // Habilita a exibição de rótulos
        color: 'black',          // Cor dos rótulos
        align: 'end',            // Alinhamento acima da barra
        anchor: 'end',           // Posiciona o rótulo acima
        font: {
          // weight: 'bold',        // Torna o texto em negrito
          size: 12               // Tamanho do texto
        },
        offset: -5,
        formatter: (value) => {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
      }
    }
  }}
    data={{
      labels: labels,
      datasets: [
        {
          label: "",
          borderColor: "#fc9403",
          backgroundColor: "#fc9403",
          data: data.map(i => i.value),
          stack: 'paid'
        },
      ]
    }} />
}

export default DetailsGraph;