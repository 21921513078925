import { useEffect, useState } from "react";
import LoaderItem from "./LoaderItem";
import AccordionItem from "./AccordionItem";
import CellAccordion from "./CellAccordion";
import { isBuildingTrial } from "@utils/checkPermiossion";

const { useProjectContext, useProjectActions } = require("@contexts/projects")

const UnitAccordion = () => {
  const [filters, setFilters] = useState({
    first: 'budget',
    second: "commited",
    third: "idc",
    fourth: 'paid'
  })
  const { activeProject: { _id, units, wbs_code_id } } = useProjectContext();
  const { loadUnits } = useProjectActions();

  useEffect(() => {
    if (_id) {
      loadUnits();
    }
  }, [_id])

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilters(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div className="accordion margin-top-24">
      {!isBuildingTrial() && <div className="accordion-selectors">
        <select name="first" onChange={handleFilterChange}>
          <option value="budget">Orçado</option>
          <option value="indexed_budget">Orçado Indexado</option>
        </select>
        <select name="fourth" onChange={handleFilterChange}>
          <option value="paid">Pago</option>
          <option value="paidStock">Pago - Estoque</option>
        </select>
        <select name="second" onChange={handleFilterChange}>
          <option value="commited">Comprometido</option>
          <option value="extraValue">Não Lançado</option>
          <option value="commited_extra">Comprometido + Não Lançado</option>
          <option value="stockValue">Em Estoque</option>
          <option value="budget_minus_paid">Saldo</option>
          <option value="indexed_balance">Saldo Indexado</option>
          <option value="accumulated">Realizado Acumulado</option>
        </select>
        <select name="third" onChange={handleFilterChange}>
          <option value="idc">IDC</option>
          <option value="stockIdc">IDC com Estoque</option>
          <option value="trend">Tendência</option>
          <option value="stockTrend">Tendência com Estoque</option>
        </select>
      </div>}

      {!units && (

        <LoaderItem />

      )}
      {units?.map((item, index) => <AccordionItem key={index} item={item} type={"unit"} typeTitle={"Unidade construtiva"} ChildAccordionComponent={item.building_unit_name === 'ITENS SEM APROPRIAÇÃO' ? null : CellAccordion} filters={filters} />)}
    </div>
  )
}

export default UnitAccordion;