import React, { useState, useEffect } from "react";
import { useBimActions } from "@contexts/bim";

const fields = [
  { label: 'Nome do Projeto', field: 'name', type: 'text' },
  { label: 'Cliente', field: 'client', type: 'text' },
  { label: 'Descrição', field: 'description', type: 'text' },
  { label: 'Data de Ínicio', field: 'startDate', type: 'date' },
  { label: 'Data de Fim', field: 'endDate', type: 'date' },
  { label: 'Status', field: 'status', type: 'select', options: ['Em Andamento', 'Concluído', 'Cancelado'] },
]

const DefaultRow = ({ item, edit, value, options }) => {
  const { field, label, type } = item
  if (type === 'select') {
    return <div>
      <label>{label}</label>
      <select onChange={edit} name={field} value={value}>
        <option disabled value=''>Selecione uma opção</option>
        {options?.map(i => {
          return <option key={i} value={i}>{i}</option>
        })}
      </select>
    </div>
  }
  return <div>
    <label>{label}</label>
    <input onChange={edit} placeholder={label} name={field} value={value} type={type} />
  </div>
}

const Project = () => {
  const [projectInfo, setProjectInfo] = useState({
    name: '',
    client: '',
    description: '',
    startDate: '',
    endDate: '',
    status: ''
  })
  const [projects, setProjects] = useState([])
  const { updateProject, getProjects } = useBimActions();

  const handleProjectEdit = (event) => {
    event.preventDefault();

    setProjectInfo(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const handleSend = async () => {
    const res = await updateProject(projectInfo)
  }

  // const list = async () => {
  //   const res = await getProjects()
  //   setProjectInfo(res.res)
  // }

  // useEffect(() => {
  //   list()
  // }, [])

  return <div className="costs">
    <h1>Cadastro de Projetos</h1>
    <div className="grid columns-3-tablet gap-8">
      {fields.map(i => {
        return <DefaultRow key={i.field} item={i} options={i.options} value={projectInfo[i.field]} edit={handleProjectEdit} />
      })}
      <button className="button button-accent" onClick={handleSend}>{projects?.filter(i => i.name === projectInfo.name).length > 0 ? 'Atualizar Projeto' : "Enviar Projeto"}</button>

    </div>
  </div>
}

export default Project;
