import {
  useDashboardUIActions,
  useDashboardUIContext,
} from "@contexts/dashboardUI";
import { useAuthContext, useAuthActions } from "@contexts/auth";
import { useProjectContext } from "@contexts/projects";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Offcanvas = () => {
  const { user } = useAuthContext();
  const { showOffcanvas } = useDashboardUIContext();
  const { toggleOffcanvas } = useDashboardUIActions();
  const { projects } = useProjectContext();
  const { updatePassword } = useAuthActions()
  const { logout } = useAuthActions()
  const { pathname } = useLocation();
  const [response, setResponse] = useState({
    done: false,
    ok: false,
    responseHead: '',
    responseText: '',
  })

  const [passwordForm, setPasswordForm] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) {
        toggleOffcanvas()
      }
    });
  }, [])

  const path = useMemo(() => {
    // return '66eacb4976d7c7909b0e9a91' //HARDCODED
    const projectId = pathname.split("/").pop();
    if (projects?.find(({ _id }) => _id === projectId)) {
      return pathname.slice(0, pathname.lastIndexOf("/"))
    }
    return pathname;
  }, [pathname, projects])

  const handleInput = (e) => {
    setPasswordForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }


  const handleSend = async (e) => {
    e.preventDefault()
    if (passwordForm.newPassword !== passwordForm.newPasswordConfirmation) {
      setResponse({
        done: true,
        ok: false,
        responseHead: "Erro",
        responseText: "As senhas não coincidem"
      })
      return;
    }
    const response = await updatePassword({ ...passwordForm, email: user.email })
    if (response.ok) {
      setPasswordForm({ newPassword: '', oldPassword: '', newPasswordConfirmation: '' })
      setResponse({
        done: true,
        ok: true,
        responseHead: "Sucesso!",
        responseText: "Senha alterada com sucesso."
      })
    } else {
      setResponse({
        done: true,
        ok: false,
        responseHead: "Erro",
        responseText: "Senha incorreta."
      })
    }
  }

  return (
    <div className={showOffcanvas ? "offcanvas active" : "offcanvas"}>
      <div className="offcanvas-container">
        <hgroup className="offcanvas-header">
          <div className="offcanvas-header-infos">
            <i className="icon icon-width-24 icon-user-circle background-accent"
              aria-hidden={true} />
            <div>
              <h2>{user?.name}</h2>
              <p>{user?.email}</p>
            </div>
          </div>
          <button onClick={toggleOffcanvas}>
            <i
              className="icon icon-width-24 icon-times background-accent"
              aria-hidden={true}
            ></i>
          </button>
        </hgroup>
        <div className="offcanvas-content">
          <hr className="divider" />
          <details>
            <summary><span>Selecionar obra</span></summary>
            <nav className="offcanvas-nav" >
              {projects?.map((project, index) => (
                <NavLink onClick={toggleOffcanvas} to={`${path}/${project._id}`} key={index}>
                  {project.building_name}
                </NavLink>
              ))}
            </nav>
          </details>
          <hr className="divider" />
          <details>
            <summary><span>Trocar senha</span></summary>
            <form className="new-password-container">
              {response.done && <div className={`alert alert-${response.ok ? "success" : "error"} margin-top-32`}>
                <h3 className="info-title">{response.responseHead}</h3>
                <p>{response.responseText}</p>
              </div>}
              <label>Senha atual</label>
              <input type="password" name='oldPassword' onChange={handleInput} value={passwordForm.oldPassword} />
              <label>Nova senha</label>
              <input type="password" name='newPassword' onChange={handleInput} value={passwordForm.newPassword} />
              <label>Confirme sua nova senha</label>
              <input type="password" name='newPasswordConfirmation' onChange={handleInput} value={passwordForm.newPasswordConfirmation} />
              <button className="button button-accent" onClick={handleSend}>Enviar</button>
            </form>
          </details>
          <hr className="divider" />
          <button onClick={logout}>
            <span>Sair</span>
            <i
              className="icon icon-width-24 icon-sign-out background-gray"
              aria-hidden={true}
            ></i>
          </button>


        </div>
      </div>
      {showOffcanvas && <div onClick={toggleOffcanvas} className="offcanvas-background" />}

    </div>
  );
};

export default Offcanvas;
