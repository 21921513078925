
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { useAuthActions } from "@contexts/auth";
import { useEffect, useState } from "react";
import useAdminCheck from "@helpers/adminCheck";


const NewUser = () => {
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    password: 'ampli2024#',
    type: '',
    companyId: [],
    buildingIds: []
  })
  const [companies, setCompanies] = useState([])
  const [buildings, setBuildings] = useState([])
  const { listCompanies } = useProjectActions();
  const { createUser } = useAuthActions();

  useAdminCheck()

  const getCompanies = async () => {
    const res = await listCompanies();
    setCompanies(res.companies)
    setBuildings(res.buildings)
  }

  useEffect(() => {
    getCompanies();
  }, [])

  const handleInput = (e) => {
    setNewUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSend = (e) => {
    e.preventDefault()
    const companies = newUser.companyId.map(i => {
      return { _id: i }
    })
    createUser({ ...newUser, companyId: companies });
  }

  const handleCompany = (e) => {
    const { value } = e.target;
    const userCompanies = newUser.companyId;
    if (!userCompanies.includes(value)) {
      setNewUser(prev => ({
        ...prev,
        companyId: [...userCompanies, value]
      }));
    } else {
      const updatedCompanies = userCompanies.filter(company => company !== value);
      const updatedBuildings = newUser.buildingIds.filter(buildingId => {
        const building = buildings.find(b => b._id === buildingId);
        return building && building.company_id !== value;
      });
      setNewUser(prev => ({
        ...prev,
        companyId: updatedCompanies,
        buildingIds: updatedBuildings
      }));
    }
  }

  const handleBuilding = (e) => {
    const { value } = e.target;
    const userBuildings = newUser.buildingIds;
    if (!userBuildings.includes(value)) {
      setNewUser(prev => ({
        ...prev,
        buildingIds: [...userBuildings, value]
      }));
    } else {
      const updatedBuildings = userBuildings.filter(i => i !== value);
      setNewUser(prev => ({
        ...prev,
        buildingIds: updatedBuildings
      }));
    }
  }

  return (
    <>
      <div>
        <form className="new-user-container">
          <label>Nome</label>
          <input type="text" name='name' onChange={handleInput} value={newUser.name} />
          <label>E-mail</label>
          <input type="text" name='email' onChange={handleInput} value={newUser.email} />
          <label>Senha</label>
          <input type="password" name='text' disabled placeholder={newUser.password} onChange={handleInput} />
          <label>Tipo</label>
          <select name="type" onChange={handleInput}>
            <option value='admin'>Admin</option>
            <option value='user'>User</option>
          </select>
          <h2>Construtoras</h2>
          <div className="companies grid gap-16 columns-3-tablet align-center">
            {companies.map(i => {
              return <div key={`${i._id}`}>
                <input type="checkbox" value={`${i._id}`} onChange={handleCompany} />
                <label>{i.name}</label>
              </div>
            })}
          </div>
          <h2>Obras</h2>
          <div className="companies grid gap-16 columns-4-tablet align-center">
            {buildings.filter(i => newUser.companyId.includes(i.company_id)).map(i => {
              return <div key={`${i._id}`}>
                <input onChange={handleBuilding} checked={newUser.buildingIds.includes(i._id)} type="checkbox" value={`${i._id}`} />
                <label>{i.building_name}</label>
              </div>
            })}
          </div>
          <button disabled={newUser.name === '' || newUser.email === "" || newUser.companyId.length === 0} className="button button-accent" onClick={handleSend}>Enviar</button>
        </form>
      </div>
    </>
  );
};

export default NewUser;
