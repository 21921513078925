import Pagination from "@components/dashboard/offcanvas/pagination"
import { useCostsActions } from "@contexts/costs"
import { formatNumberToCurrency } from "@utils/formatNumber"
import { useEffect, useState } from "react"

const AccordionCredor = ({ item, count }) => {
  const { getCredors } = useCostsActions()
  const [toggle, setToggle] = useState(false)
  const [items, setItems] = useState([])

  const getItems = async () => {
    const res = await getCredors(item.name_credor, 1, 'precise')
    setItems(res.res)
    setToggle(true)
  }

  useEffect(() => {
    setToggle(false)
  }, [count])

  const handleToggle = () => {
    if (toggle) {
      setToggle(false)
      return
    }
    else {
      getItems()
    }
  }

  return <div className="credor-accordion">
    <div className="credor-body">
      <div>
        <button
          className={`accordion-toggle ${toggle ? "active" : ""}`}
          onClick={handleToggle}
        />
        <div className="grid">
          <span className="accordion-label">Nome</span>
          <span className="accordion-title">{item.name_credor}</span>
        </div>
      </div>
      <div className="data">
        <span className="accordion-label">CNPJ</span>
        <span className="accordion-title">{item.cnpj_credor}</span>
      </div>
    </div>
    {toggle && items.map(i => {
      return <div className="credor-sub">
        <div className="data">
          <span className="accordion-label">Obra - Construtora</span>
          <span className="accordion-title">{i.building} - {i.company}</span>
        </div>
        <div className="data">
          <span className="accordion-label">Descrição</span>
          <span className="accordion-title">{i.resource_description}</span>
        </div>
        <div className="data">
          <span className="accordion-label">Quantidade</span>
          <span className="accordion-title">{i.quantity}</span>
        </div>
        <div className="data">
          <span className="accordion-label">Unitário - {i.unit_of_measure}</span>
          <span className="accordion-title">{formatNumberToCurrency(i.unit_price)}</span>
        </div>
        <div className="data">
          <span className="accordion-label">Total</span>
          <span className="accordion-title">{formatNumberToCurrency(i.total_price)}</span>
        </div>
      </div>
    })}
  </div>
}

const AccordionResource = ({ item }) => {

  return <div className="credor-accordion">
    <div className="credor-resource">
      <div className="data">
        <span className="accordion-label">Descrição</span>
        <span className="accordion-title">{item.resource_description}</span>
      </div>
      <div className="data">
        <span className="accordion-label">Nome</span>
        <span className="accordion-title">{item.name_credor}</span>
      </div>
      <div className="data">
        <span className="accordion-label">CNPJ</span>
        <span className="accordion-title">{item.cnpj_credor}</span>
      </div>
      <div className="data">
        <span className="accordion-label">unit_price</span>
        <span className="accordion-title">{item.unit_price}</span>
      </div>
      <div className="data">
        <span className="accordion-label">total_price</span>
        <span className="accordion-title">{item.total_price}</span>
      </div>
      <div className="data">
        <span className="accordion-label">quantity</span>
        <span className="accordion-title">{item.quantity}</span>
      </div>
      <div className="data">
        <span className="accordion-label">building</span>
        <span className="accordion-title">{item.building}</span>
      </div>
    </div>
  </div>
}

const Creditor = () => {
  const { getCredors } = useCostsActions()
  const [page, setPage] = useState(1)
  const [credors, setCredors] = useState([])
  const [total, setTotal] = useState(0)
  const [nameToSearch, setNameToSearch] = useState('')
  const [count, setCount] = useState(0)
  const [type, setType] = useState('')

  const list = async (type) => {
    if (type === 'resource') {
      setType('resource')
    } else {
      setType('')
    }
    const res = await getCredors(nameToSearch, page, type)
    setCredors(res.res)
    setTotal(res.total)
  }

  useEffect(() => {
    list()
  }, [page])

  useEffect(() => {
    setCount(c => c + 1)
  }, credors)

  const goBack = () => {
    setPage(prev => {
      if (prev === 1) return 1
      return prev - 1
    })
  }

  const goForward = () => {
    setPage(prev => {
      if (prev === Math.ceil(total / 40)) return Math.ceil(total / 40)
      return prev + 1
    })
  }

  const handleEdit = (e) => {
    setNameToSearch(e.target.value)
  }

  return <div className="costs">
    <h2>Fornecedores</h2>
    <div className="flex gap-8">
      <input value={nameToSearch} onChange={handleEdit} />
      <button className="button button-accent" onClick={() => list('loose')}>BUSCAR POR FORNECEDOR</button>
      <button className="button button-accent" onClick={() => list('resource')}>BUSCAR POR INSUMO</button>
    </div>
    <Pagination count={total} currentPage={page} goBack={goBack} goForward={goForward} perPage={40} />
    <div className="gap-4 grid">
      {credors?.length > 0 && (
        credors.map(i => {
          return type === '' ? <AccordionCredor item={i} count={count} /> : <AccordionResource item={i} count={count} />
        })
      )}

    </div>

  </div>
}

export default Creditor