import { useCostsActions, useCostsContext } from "@contexts/costs";
import { useEffect, useState } from "react";

const findRecent = (arr) => {
  return arr.reduce((latest, obj) => {
    return new Date(obj.date) > new Date(latest.date) ? obj : latest;
  });
};


export const CubSelector = () => {
  const { getIndexers, updateCubFilter, updateAllCubs } = useCostsActions();
  const { cubFilter } = useCostsContext();
  const [cubList, setCubList] = useState([])
  const [currentCub, setCurrentCub] = useState()

  const list = async () => {
    const cubs = await getIndexers()
    await updateAllCubs(cubs.res)
    const arr = []
    const states = [...new Set(cubs.res.map(i => i.state))]
    states.map(i => {
      arr.push(findRecent(cubs.res.filter(j => j.state === i)))
    })
    setCubList(arr)
    if (cubFilter < 2) {
      setCurrentCub(+cubFilter)
    } else {
      setCurrentCub(+arr.filter(i => +i.value === +cubFilter)[0].value)
    }
  }

  useEffect(() => {
    list()
  }, [])

  const handleChange = (e) => {
    const { value } = e.target
    updateCubFilter(+value)
    setCurrentCub(+value)
  }

  return <select onChange={handleChange} value={currentCub}>
    <option value={1}>R$</option>
    <option value={0}>CUB Original</option>
    {cubList.map((i) => {
      return <option key={i._id} value={+i.value}>Valor Presente: {i.state} - {i.date}</option>
    })}
  </select>
}

export const calculateCub = (state, date, value) => {
  const { allCubs, cubFilter = 1 } = useCostsContext()
  const slicedDate = date?.slice(0, 7)

  if (cubFilter === 1) {
    return value;
  }

  const cubValue = allCubs.filter(i => i.state === state && i.date === slicedDate)[0]?.value ?? findRecent(allCubs.filter(i => i.state === state)).value

  if (cubFilter === 0) {
    return value / cubValue;
  }

  return ((value / cubValue) * cubFilter)


}