import { createContext, useMemo, useReducer } from 'react';

export default (initialState, processActions, reducer) => {
  const Context = createContext(initialState);
  let actions;

  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    actions = processActions(state, dispatch);
    return <Context.Provider value={state}>{children}</Context.Provider>;
  };

  return {
    Context,
    Provider,
    actions: () => {
      return actions;
    },
  };
};
