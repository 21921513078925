const Pagination = ({ currentPage, count, goBack, goForward, perPage }) => {
  return <div className="pagination-container">
    <p>
      {((currentPage - 1) * perPage) + 1} - {currentPage * perPage > count ? count : currentPage * perPage} de {count}
    </p>
    <button onClick={goBack}>
      <i
        className="icon icon-width-24 icon-angle-left background-accent"
      ></i>
    </button>
    <button onClick={goForward}>
      <i
        className="icon icon-width-24 icon-angle-right background-accent"
        aria-hidden={true}
      ></i>
    </button>
  </div>
}

export default Pagination