import Loader from "@components/Loader";
import AdvancementAccordion from "@components/dashboard/advancements/AdvancementAccordion";
import { useDashboardUIActions } from "@contexts/dashboardUI";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { formatNumberToCurrency } from "@utils/formatNumber";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from 'react-router-dom';


const Advancements = () => {
  const { getAppropriation } = useProjectActions();
  const [resources, setResources] = useState([])
  const [loading, setLoading] = useState(false)

  const { activeProject } = useProjectContext()
  const getItems = async () => {
    setLoading(true)
    const response = await getAppropriation(activeProject?.appropriation_level, activeProject?.wbs_code_id)
    setResources(response.uniqueDescs)
    setLoading(false)
  }

  useEffect(() => {
    getItems()
  }, [activeProject])

  return (
    <>
      {loading && <div className="margin-top-24"><Loader /></div>}
      <div className="resources-container">
        <div className="accordion">
          {resources?.length > 0 && (
            <>
              <div className="accordion margin-top-24">
                {resources.map(i => {
                  return <AdvancementAccordion item={i} />
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Advancements;
