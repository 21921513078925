import { useCostsActions } from "@contexts/costs";
import { useEffect, useMemo, useState } from "react";
import { getPercentage, getUniques, itemGroups } from "./components/utils";
import Card from "@components/Card";
import Modal from "@components/Modal";
import { formatNumberToCurrency } from "@utils/formatNumber";
import { CubSelector, calculateCub } from "./components/cubSelector";

const Summary = () => {
  const { getBuildings } = useCostsActions()
  const [buildings, setBuildings] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [filters, setFilters] = useState({
    state: '',
    city: '',
    type: '',
    standard: ''
  })
  const [buildingsToSum, setBuildingstoSum] = useState([])
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });
  const [minMax, setMinMax] = useState({
    minArea: '',
    maxArea: ''
  })
  const [chosenBuilding, setChosenBuilding] = useState({})
  const [modalVisible, setModalVisible] = useState(false)

  const list = async () => {
    const res = await getBuildings()
    setBuildings(res.res)
    setBuildingstoSum(res.res)
    setStates(getUniques(res.res, 'state'))
    const min = res.res.reduce((min, obj) => {
      return obj.build_area < min ? obj.build_area : min;
    }, Infinity);
    const max = res.res.reduce((max, obj) => {
      return obj.build_area > max ? obj.build_area : max;
    }, -Infinity);
    setMinMax({ minArea: min, maxArea: max })
  }

  useEffect(() => {
    list()
  }, [])

  useEffect(() => {
    const filteredBuildings = buildings.filter((building) => {
      return Object.entries(filters).every(([key, value]) => {
        if (value === '') return true;
        return building[key]?.toString().toLowerCase() === value.toLowerCase();
      });
    });

    setBuildingstoSum(filteredBuildings);
  }, [filters, buildings]);

  useEffect(() => {
    setCities(getUniques(buildings.filter(i => i.state === filters.state), 'city'))
  }, [filters.state])


  const handleFilters = (e) => {
    setFilters(prev => {
      if (e.target.name === 'state') return { ...prev, [e.target.name]: e.target.value, city: '' }
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const filterData = (label, field) => {
    return <div>
      <h2>Por {label}</h2>
      {[...new Set(buildingsToSum.map(i => { return i[field] }))].map(i => {
        return <p key={i}>{i} - {buildingsToSum.filter(j => j[field] === i).length}</p>
      })}
    </div>
  }

  const handleSort = (key) => {
    setSortConfig((prev) => {
      if (prev.key === key) {
        return { key, direction: prev.direction === "ascending" ? "descending" : "ascending" };
      }
      return { key, direction: "ascending" };
    });
  };

  const sortedItems = useMemo(() => {
    const filtered = buildingsToSum.filter((item) => {
      const area = item.build_area;
      const isAboveMin = minMax.minArea === "" || area >= Number(minMax.minArea);
      const isBelowMax = minMax.maxArea === "" || area <= Number(minMax.maxArea);
      return isAboveMin && isBelowMax;
    });
    if (!sortConfig.key) return filtered;
    return filtered.sort((a, b) => {
      let aValue;
      let bValue;
      if (sortConfig.key === 'perM') {
        aValue = (a.total) / (a.build_area)
        bValue = (b.total) / (b.build_area)
      } else {
        aValue = a[sortConfig.key];
        bValue = b[sortConfig.key];
      }
      if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    });
  }, [buildings, sortConfig, buildingsToSum]);

  const handleMinMax = (e) => {
    const { name, value } = e.target
    setMinMax(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChoose = (idx) => {
    setChosenBuilding(sortedItems[idx])
    setModalVisible(true)
  }


  return <div className="costs">
    {modalVisible && <Modal close={() => setModalVisible(false)}>
      <h2>{chosenBuilding.name}</h2>
      <div className="grid columns-2-tablet gap-8 margin-bottom-16">
        <Card label='Construtora' content={chosenBuilding.company} />
        <Card label='Local' content={`${chosenBuilding.city} - ${chosenBuilding.state}`} />
        <Card label='Data de referência' content={chosenBuilding.date.slice(0, 7)} />
        <Card label='Área' content={chosenBuilding.build_area} />
        <Card label='Valor total' content={formatNumberToCurrency(chosenBuilding.total)} />
        <Card label='Valor por m²' content={(chosenBuilding.total / chosenBuilding.build_area).toFixed(2)} />
      </div>
      <div className="grid columns-5-tablet gap-8">
        <span className="column-span-2">Categoria</span>
        <span>Total</span>
        <span>Porcentagem</span>
        <span>Por m2</span>
      </div>
      <div className="resume-details">
        {chosenBuilding?.groups.map(i => {
          return <><p className="column-span-2">{i.type}</p><p>{formatNumberToCurrency(calculateCub(chosenBuilding.state, chosenBuilding.date, i.total))}</p><p>{getPercentage(i.total, chosenBuilding.total)}%</p><p>{formatNumberToCurrency(i.total / chosenBuilding.build_area)}</p></>
        })}
      </div>
    </Modal>}
    <div>
      <h2>Orçamentos Resumidos</h2>
      <CubSelector />
    </div>
    <div className="grid columns-4-tablet gap-16">
      <div>
        <span>Estado</span>
        <select value={filters.state} name="state" onChange={handleFilters}>
          <option value=''>Todos estados</option>
          {states.map((i) => {
            return <option key={i} value={i}>{i}</option>
          })}
        </select>
      </div>
      <div>
        <span>Cidade</span>
        <select value={filters.city} disabled={filters.state === ''} name="city" onChange={handleFilters}>
          <option value=''>Todas cidades</option>
          {cities.map((i) => {
            return <option key={i} value={i}>{i}</option>
          })}
        </select>
      </div>
      <div>
        <span>Tipo de Obra</span>
        <select value={filters.type} name="type" onChange={handleFilters}>
          <option value=''>Todos tipos</option>
          <option>Residencial</option>
          <option>Casas</option>
          <option>Hoteleiro</option>
          <option>Casas / Infra</option>
          <option>Comercial</option>
          <option>Residencial / Comercial</option>
        </select>
      </div>
      <div>
        <span>Padrão de Obra</span>
        <select value={filters.standard} name="standard" onChange={handleFilters}>
          <option value=''>Todos padrões</option>
          <option>Alto</option>
          <option>Médio-alto</option>
          <option>Médio</option>
        </select>
      </div>
    </div>
    <div className="grid columns-4-tablet gap-8">
      {/* <Card label="VALOR TOTAL" content={formatNumberToCurrency(calculateCub('Santa Catarina', '', buildingsToSum.reduce((a, b) => { return a + +b.total }, 0)))} /> */}
      <Card label="VALOR TOTAL" content={formatNumberToCurrency(buildingsToSum.reduce((a, b) => { return a + calculateCub(b.state, b.date, b.total) }, 0))} />
      {/* <Card label="VALOR TOTAL" content={formatNumberToCurrency('', '', buildingsToSum.reduce((a, b) => { return a + +b.total }, 0))} /> */}
      <Card label="ÁREA TOTAL CONSTRUÍDA" content={buildingsToSum.reduce((a, b) => { return a + +b.build_area }, 0)?.toFixed(2)} />
      <Card label="QUANTIDADE DE OBRAS" content={buildingsToSum.length} />
      <Card label="VALOR MÉDIO POR M²" content={formatNumberToCurrency((buildingsToSum.reduce((a, b) => { return a + +b.total }, 0)) / (buildingsToSum.reduce((a, b) => { return a + +b.build_area }, 0)))} />
    </div>
    <div className="grid columns-5-tablet gap-8">
      {filterData('estado', 'state')}
      {filterData('cidade', 'city')}
      {filterData('tipo de obra', 'type')}
      {filterData('padrão de obra', 'standard')}
      {filterData('tipo de orçamento', 'budget_type')}
    </div>

    <div className="margin-bottom-16">
      <label className="margin-bottom-8">
        Área Mínima:
        <input
          type="number"
          value={minMax.minArea}
          name='minArea'
          onChange={handleMinMax}
        />
      </label>
      <label>
        Área Máxima:
        <input
          type="number"
          value={minMax.maxArea}
          name='maxArea'
          onChange={handleMinMax}
        />
      </label>
    </div>
    <div className="grid gap-4 columns-7-tablet">
      <p onClick={() => handleSort("name")}>Obra</p>
      <p onClick={() => handleSort("company")}>Construtora</p>
      <p onClick={() => handleSort("state")}>Estado</p>
      <p onClick={() => handleSort("city")}>Cidade</p>
      <p onClick={() => handleSort("build_area")}>Área</p>
      <p onClick={() => handleSort("total")}>Valor Total</p>
      <p onClick={() => handleSort("perM")}>Valor/m²</p>
    </div>
    {sortedItems.map((i, index) => (
      <div className="grid gap-4 columns-7-tablet" onClick={() => handleChoose(index)}>
        <p>{i.name}</p>
        <p>{i.company}</p>
        <p>{i.state}</p>
        <p>{i.city}</p>
        <p>{i.build_area}</p>
        <p>{formatNumberToCurrency(calculateCub(i.state, i.date, i.total))}</p>
        <p>{formatNumberToCurrency(i.total / i.build_area)}</p>
      </div>
    ))}

  </div>
}

export default Summary;