import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import ServiceAccordion from "./ServiceAccordion";
import AccordionItem from "./AccordionItem";

const SubstepAccordion = ({parent, filters}) => {
  
  const {activeProject: {_id, substeps = {}}} = useProjectContext();
  const { loadSubsteps } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadSubsteps(parent);
    } 
  },[_id])

  const stepSubsteps = useMemo(() => substeps[parent], [parent, substeps])

  return (
    <>
      {!stepSubsteps && <LoaderItem />}
      {stepSubsteps?.map((item, index) => <AccordionItem key={index} item={item} type={"substep"} typeTitle={"Subetapa"} ChildAccordionComponent={ServiceAccordion} filters={filters}/>)}
    </>
  )
}

export default SubstepAccordion;