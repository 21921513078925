import { useDashboardUIActions } from "@contexts/dashboardUI";
import { useProjectActions } from "@contexts/projects";
import { formatNumberToCurrency, formatNumberToLocale } from "@utils/formatNumber";
import { useProjectContext } from "@contexts/projects";
import { isBuildingTrial } from "@utils/checkPermiossion";

const { useState, useCallback } = require("react")

const AccordionItem = ({ item, type, typeTitle, ChildAccordionComponent }) => {
  const { toggleItemDetailOffcanvas } = useDashboardUIActions();
  const { selectDetail } = useProjectActions();
  const { activeProject: { wbs_code_id } } = useProjectContext();

  const [toggle, setToggle] = useState(false)
  const onShowDetail = useCallback(() => {
    selectDetail(item, type)
    toggleItemDetailOffcanvas()
  }, [item, type])

  return (

    <div className="accordion-item">
      <div className="accordion-item-body">
        <div>
          <div className="flex align-center gap-8">
            {ChildAccordionComponent && (
              <button
                className={`accordion-toggle ${toggle ? "active" : ""}`}
                onClick={() => setToggle(!toggle)}
              ></button>
            )}
            <div className="grid">
              <span className="accordion-label">EAP #{item.building_unit_id}{item.wbs ? `.${item.wbs}` : ""} / {typeTitle}</span>
              <span className="accordion-title">{item.description ?? item.building_unit_name}</span>
            </div>
          </div>
        </div>
        <div className="column-span-2-tablet">
          <div className="flex wrap-wrap wrap-nowrap-tablet gap-16 align-center">
            <div className="accordion-content columns-5-tablet">
              <div className="column-span-2-tablet" aria-hidden={true}></div>
              <div className="data">
                <span className="label">Desembolso Planejado (R$)</span>
                <span className="value color">{formatNumberToLocale(item.filteredDisbursement)}</span>
              </div>
              {!isBuildingTrial() && <><div className="data">
                <span className="label">Pago (R$)</span>
                <span className="value color">{formatNumberToLocale(item.filteredPaid)}</span>
              </div>
                <div className="data">
                  <span className="label">A pagar (R$)</span>
                  <span className="value color">{formatNumberToLocale(item.filteredToPay)}</span>
                </div> </>}
            </div>
            <div className="accordion-actions">
              <button className="toggle-offcanvas" onClick={onShowDetail}>
                <i
                  className="icon icon-width-16 icon-ellipsis-h"
                  aria-hidden={true}
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {toggle && <ChildAccordionComponent parent={item.wbs_code_id} />}
    </div>
  )
}

export default AccordionItem;