import Card from "@components/Card";
import { cardTexts } from "@utils/cardTexts";
import { formatDateDMY, formatDateMY } from "@utils/formatDate";
import { formatNumberToCurrency, formatNumberToLocale } from "@utils/formatNumber";

const { useProjectContext } = require("@contexts/projects");

const BasicInfo = () => {
  const { activeProject } = useProjectContext();
  return (
    <div className="grid gap-24 columns-2-tablet columns-4-laptop-l">
      <Card label='Início da obra' content={formatDateDMY(activeProject?.start_date) ?? "-"} tooltip={cardTexts.BUILDING_START} />
      <Card label='Término da obra' content={activeProject?.prediction_of_the_end ? formatDateMY(activeProject?.prediction_of_the_end) : formatDateMY(activeProject?.end_date)} subcontent={`Previsão original: ${activeProject?.end_date ? formatDateMY(activeProject?.end_date) : '-'}`} tooltip={cardTexts.BUILDING_PREVISION} />
      <Card label={`${activeProject.indexer?.description} - ${activeProject.indexer?.type} (R$)`}
        content={`${formatNumberToLocale(activeProject?.indexer?.value)} - ${formatDateMY(activeProject?.indexer?.date_used_in)}`}
        subcontent={`Valor original: ${formatNumberToLocale(activeProject?.originalIndexer?.value)} - ${formatDateMY(activeProject?.originalIndexer?.date_used_in)}`}
        tooltip={cardTexts.CUB}
      />
      <Card label='Área construida (M²)' content={formatNumberToLocale(activeProject.area)} tooltip={cardTexts.AREA} />
    </div>
  );
};

export default BasicInfo;
