import { Link } from "react-router-dom";

import Loader from "@components/Loader";

const CodeValidation = () => {
  const isLoading = false;

  return (
    <form id="access-form">
      {isLoading && <Loader />}

      <hgroup>
        <h1>Verifique seu e-mail</h1>
        <h2 className="margin-top-16">
          Você recebeu um e-mail com um código de verificação. Insira o código
          no campo abaixo:
        </h2>
      </hgroup>
      <fieldset className="grid gap-16 margin-top-32">
        <label>
          <span>Código de verificação</span>
          <input
            type="text"
            name={""}
            value={""}
            onChange={() => {}}
            required={true}
          />
        </label>
      </fieldset>
      <div className="flex align-center justify-content-space-between gap-16 wrap-wrap margin-top-32">
        <Link to="/access" className="text-small">
          Voltar
        </Link>
        <button className="button button-accent">Continuar</button>
      </div>
      <p className="text-small margin-top-32">
        Não recebeu o código?{" "}
        <Link to="/access/recuperar-senha">Solicitar novo código</Link>
      </p>
    </form>
  );
};

export default CodeValidation;
