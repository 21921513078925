import state from "./state.js";
import actions from "./actions.js";
import reducer from "./reducer.js";
import createContext from "../createContext.js";
import { useContext } from "react";

const {
  Context: bimContext,
  Provider: bimProvider,
  actions: processedActions,
} = createContext(state, actions, reducer);

export const useBimContext = () => {
  return useContext(bimContext);
};

export const useBimActions = () => {
  return processedActions();
};

export default bimProvider;
