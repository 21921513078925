import { useBimActions, useBimContext } from "@contexts/bim";
import { useEffect, useState } from "react";

const defaultClassification = {
  name: '',
  shortname: '',
  description: ''
};

const Classifications = () => {
  const { getClassifications, updateClassification } = useBimActions();
  const [classificationInfo, setClassificationInfo] = useState(defaultClassification);
  const [classifications, setClassifications] = useState([]);

  const listClassifications = async () => {
    try {
      const res = await getClassifications();
      if (res && Array.isArray(res)) {
        setClassifications(res);
      } else {
        console.error('Resposta inválida ao buscar classificações:', res);
        setClassifications([]);
      }
    } catch (error) {
      console.error('Erro ao buscar classificações:', error);
      setClassifications([]);
    }
  };

  useEffect(() => {
    listClassifications();
  }, []);

  const handleSend = async () => {
    try {
      await updateClassification(classificationInfo);
      listClassifications();
    } catch (error) {
      console.error('Erro ao atualizar classificação:', error);
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setClassificationInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClassificationEdit = (index) => {
    setClassificationInfo(classifications[index]);
  };

  const reset = () => {
    setClassificationInfo(defaultClassification);
  };

  return (
    <div className="classifications">
      <h2>Classificações</h2>
      <button onClick={reset} className="button button-secondary margin-bottom-16">
        Nova Classificação
      </button>
      <div className="form grid columns-3-tablet gap-8 margin-bottom-16">
        <div>
          <label>Nome</label>
          <input
            type="text"
            onChange={handleEdit}
            value={classificationInfo.name}
            placeholder="Nome"
            name="name"
          />
        </div>
        <div>
          <label>Abreviação</label>
          <input
            type="text"
            onChange={handleEdit}
            value={classificationInfo.shortname}
            placeholder="Abreviação"
            name="shortname"
          />
        </div>
        <div>
          <label>Descrição</label>
          <input
            type="text"
            onChange={handleEdit}
            value={classificationInfo.description}
            placeholder="Descrição"
            name="description"
          />
        </div>
        <button onClick={handleSend} className="button button-accent">
          Enviar
        </button>
      </div>

      <div className="classification-list margin-top-16">
        {classifications.length > 0 ? (
          <div className="grid columns-4-tablet">
            <p>Nome</p>
            <p>Abreviação</p>
            <p>Descrição</p>
            <p>Ações</p>
            {classifications.map((classification, index) => (
              <>
                <p>{classification.name}</p>
                <p>{classification.shortname}</p>
                <p className="description">{classification.description}</p>
                <button onClick={() => handleClassificationEdit(index)}>Editar</button>
              </>
            ))}
          </div>
          // <table className="table">
          //   <thead>
          //     <tr>
          //       <th>Nome</th>
          //       <th>Abreviação</th>
          //       <th>Descrição</th>
          //       <th>Ações</th>
          //     </tr>
          //   </thead>
          //   <tbody>
          //     {classifications.map((classification, index) => (
          //       <tr key={classification._id}>
          //         <td>{classification.name}</td>
          //         <td>{classification.shortname}</td>
          //         <td className="description">{classification.description}</td>
          //         <td>
          //           <button onClick={() => handleClassificationEdit(index)}>Editar</button>
          //         </td>
          //       </tr>
          //     ))}
          //   </tbody>
          // </table>
        ) : (
          <p>Nenhuma classificação encontrada.</p>
        )}
      </div>
    </div>
  );
};

export default Classifications;
