import { formatNumberToCurrency } from "@utils/formatNumber"

const DetailsAccordion = ({ item, select }) => {
  return <div className="advancement-item-body" onClick={select}>
    <h3 className="wbs">{item.wbs}</h3>
    <div className="advancement-info-resource-month">
      <span className="label">Data</span>
      <span className="label">Valor</span>
    </div>
    {item?.disbursements?.length > 0 && item.disbursements.map(i => {
      return <div className="advancement-info-resource-month values">
        <span className="value">{i.month}/{i.year}</span>
        <span className="value">{formatNumberToCurrency(i.value)}</span>
      </div>
    })}
  </div>
}

export default DetailsAccordion