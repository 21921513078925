import Card from "@components/Card";
import { useProjectContext } from "@contexts/projects";
import { cardTexts } from "@utils/cardTexts";
import { checkIfCubBased, checkLabel, formatNumberToLocale, formatToPercentage } from "@utils/formatNumber";

const FinancialInfo = () => {
  const { activeProject, cubBased } = useProjectContext()
  const { budget, committedTotal, trend, lastMeasure, indexedBalance } = activeProject

  const diff = formatToPercentage(lastMeasure?.measured_accumulated_percentage - lastMeasure?.baseline_accumulated_percentage);

  return (
    <div className="grid gap-24 columns-2-tablet columns-4-laptop-l margin-top-40 margin-bottom-40">
      <Card label={`Orçamento base (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(budget?.value))} tooltip={cardTexts.BASE} />
      <Card label={`Orçamento indexado (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(indexedBalance?.budget_indexed))} tooltip={cardTexts.BUDGET_INDEXED} />
      <Card label={`Total comprometido (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(committedTotal?.value))} tooltip={cardTexts.COMMITED_TOTAL} />
      <Card label={`Verba disponível (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(budget?.value - (committedTotal?.value ?? 0)))} tooltip={cardTexts.AVAILABLE_FUNDS} />
      <Card label='Base acumulada (%)' content={formatToPercentage(lastMeasure?.baseline_accumulated_percentage)} tooltip={cardTexts.ACCUMULATED_BASE} />
      <Card label='Agregado acumulado (%)' content={formatToPercentage(lastMeasure?.measured_accumulated_percentage)} tooltip={cardTexts.ACCUMULATED_AGGREGATED} />
      <Card label='Diferença - Base X Agregado (%)' content={diff} color={diff > 0 ? 'green' : 'red'} tooltip={cardTexts.BASE_AGGREGATED_DIF} />
      <Card label={`Tendência (${checkLabel()})`} content={formatNumberToLocale(checkIfCubBased(trend?.value))} tooltip={cardTexts.TREND} />
    </div>
  )
};

export default FinancialInfo;
