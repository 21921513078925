import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@contexts/auth";

const useAdminCheck = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.type !== "admin") {
      navigate("/dashboard");
    }
  }, [user, navigate]);
};

export default useAdminCheck;