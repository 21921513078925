import { useCostsActions } from "@contexts/costs"
import { useEffect, useState } from "react"
import { getPercentage, getUniques, itemGroups } from "./components/utils"
import { formatNumberToCurrency } from "@utils/formatNumber"
import { CubSelector, calculateCub } from "./components/cubSelector"

const Comparison = () => {
  const { getBuildings, getBuildingTotals } = useCostsActions()
  const [buildings, setBuildings] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [filters, setFilters] = useState({
    state: '',
    city: '',
    type: '',
    standard: '',
    name: '',
    min: 0,
    max: 0,
  })
  const [buildingsToShow, setBuildingsToShow] = useState([])
  const [arrayToShow, setArrayToShow] = useState([])
  const [buildingsToCompare, setBuildingsToCompare] = useState([])
  const [buildingTotals, setBuildingTotals] = useState([])

  const list = async () => {
    const res = await getBuildings()
    setBuildings(res.res)
    setBuildingsToShow(res.res)
    setStates(getUniques(res.res, 'state'))
    const min = res.res.reduce((min, obj) => {
      return obj.build_area < min ? obj.build_area : min;
    }, Infinity);
    const max = res.res.reduce((max, obj) => {
      return obj.build_area > max ? obj.build_area : max;
    }, -Infinity);
    setFilters({ ...filters, min: min, max: max })
  }

  useEffect(() => {
    list()
  }, [])

  useEffect(() => {
    const filteredBuildings = buildings.filter((building) => {
      return Object.entries(filters).every(([key, value]) => {
        if (value === '') return true;
        if (key === 'min') return +building.build_area >= +value
        if (key === 'max') return +building.build_area <= +value
        return building[key]?.toString().toLowerCase().includes(value.toLowerCase());
      });
    });

    setBuildingsToShow(filteredBuildings);
  }, [filters, buildings]);

  useEffect(() => {
    setCities(getUniques(buildings.filter(i => i.state === filters.state), 'city'))
  }, [filters.state])

  useEffect(() => {
    setBuildingsToCompare(buildings.filter(i => arrayToShow.includes(i.name)))
  }, [arrayToShow])

  const handleFilters = (e) => {
    setFilters(prev => {
      if (e.target.name === 'state') return { ...prev, [e.target.name]: e.target.value, city: '' }
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const handleSelect = async (e) => {
    const id = buildings.filter(i => i.name === e.target.name)[0]._id
    const res = await getBuildingTotals(id)
    setBuildingTotals(prev => ([...prev, { name: e.target.name, totals: res.res }]))
    const { name } = e.target;

    setArrayToShow((prev) => {
      if (prev.includes(name)) {
        return prev.filter((item) => item !== name);
      } else {
        return [...prev, name];
      }
    });
  };

  return <div className="costs">
    <div className="costs-header">
      <h2>Comparação</h2>
      <CubSelector />
    </div>
    <div className="grid columns-3-tablet gap-16">
      <div>
        <span>Nome</span>
        <input value={filters.name} name="name" onChange={handleFilters} />
      </div>
      <div>
        <span>Estado</span>
        <select value={filters.state} name="state" onChange={handleFilters}>
          <option value=''>Todos estados</option>
          {states.map((i) => {
            return <option key={i} value={i}>{i}</option>
          })}
        </select>
      </div>
      <div>
        <span>Cidade</span>
        <select value={filters.city} disabled={filters.state === ''} name="city" onChange={handleFilters}>
          <option value=''>Todas cidades</option>
          {cities.map((i) => {
            return <option key={i} value={i}>{i}</option>
          })}
        </select>
      </div>
      <div>
        <span>Tipo de Obra</span>
        <select value={filters.type} name="type" onChange={handleFilters}>
          <option value=''>Todos tipos</option>
          <option>Residencial</option>
          <option>Casas</option>
          <option>Hoteleiro</option>
          <option>Casas / Infra</option>
          <option>Comercial</option>
          <option>Residencial / Comercial</option>
        </select>
      </div>
      <div>
        <span>Padrão de Obra</span>
        <select value={filters.standard} name="standard" onChange={handleFilters}>
          <option value=''>Todos padrões</option>
          <option>Alto</option>
          <option>Médio-alto</option>
          <option>Médio</option>
        </select>
      </div>
      <div className="grid columns-2-tablet">
        <span>Área Mínima</span>
        <span>Área Máxima</span>
        <input value={filters.min} name="min" onChange={handleFilters} />
        <input value={filters.max} name="max" onChange={handleFilters} />
      </div>
    </div>
    <div className="grid columns-2-tablet">
      <div>
        {buildingsToShow.map(i => {
          return <div className="margin-bottom-8 flex gap-8" key={i.name}><input type="checkbox" checked={arrayToShow.includes(i.name)} onChange={handleSelect} name={i.name} /><label>{i.name} - {i.company} - {i.build_area}</label></div>
        })}
      </div>
      <div>
        {itemGroups.map(i => {
          return <div className="padding-bottom-16">
            <h2>{i}</h2>
            {buildingsToCompare.map(j => {
              const total = buildingTotals?.filter(k => k.name === j.name)[0]?.totals?.filter(k => k.type === i)[0]?.data?.reduce((a, b) => a + b.total, 0) ?? 0
              return <div className="flex gap-16">
                <p>{j.name}</p>
                <p>{formatNumberToCurrency(calculateCub(j.state, j.date, total))} - {getPercentage(total, j.total)}% - Por m2: {formatNumberToCurrency(total / j.build_area)}
                </p>
              </div>
            })}
          </div>
        })}
      </div>
    </div>


  </div>
}

export default Comparison