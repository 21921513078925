import Loader from "@components/Loader";
import { useDashboardUIActions } from "@contexts/dashboardUI";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { formatNumberToCurrency } from "@utils/formatNumber";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from 'react-router-dom';


const Curve = () => {
  const { toggleItemDetailOffcanvas } = useDashboardUIActions();
  const { updateCurveID, selectDetail, getResourceEAP, getCurve } = useProjectActions();
  const [resources, setResources] = useState([])
  const [allResources, setAllResources] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState('')

  const { activeProject } = useProjectContext()
  const navigate = useNavigate();

  const filterResources = async () => {
    setLoading(true)
    const response = await getCurve(activeProject?.wbs_code_id)
    setResources([...response.items])
    setAllResources([...response.items])
    setLoading(false)
  }

  useEffect(() => {
    filterResources()
  }, [activeProject])

  const renderRow = (label, value) => {
    return <div className="data">
      <span className="accordion-label">{label}</span>
      <span className="value">{value}</span>
    </div>
  }

  const handleSearchString = (e) => {
    const { value } = e.target
    setSearchString(value)
    if (value.length < 3) {
      setResources(allResources)
      return
    }
    const newArray = allResources.filter(i => i.name.toLowerCase().includes(value.toLowerCase()))
    setResources(prev => [...newArray])
  }


  const onShowDetail = (i) => {
    selectDetail(i, 'resource')
    getResourceEAP({ id: i.id, building_id: activeProject.building_id, building_wbs: activeProject.wbs_code_id })
    toggleItemDetailOffcanvas()
  }

  const RenderRows = () => {
    return resources?.map((resource, index) => {
      return <div key={index} className={`accordion-item offcanvas-accordion-item curve ${resource.totalPercentage < 50 ? 'curve-A' : (resource.totalPercentage < 80 ? 'curve-B' : 'curve-C')}`}>
        <div>
          <div className="flex align-center justify-content-space-between gap-8">
            <div className="grid">
              <span className="resource-id margin-bottom-0 margin-top-12 accordion-label clickable-id" onClick={() => onShowDetail(resource)} name={resource.id}>Insumo - {resource.id}</span>
              <span className="accordion-title margin-top-12" title={resource.name} >{resource.name} - {resource.measurement_unit}</span>
            </div>
            <button className="toggle-offcanvas" onClick={() => onShowDetail(resource)}>
              <i
                className="icon icon-width-24 icon-ellipsis-v"
                aria-hidden={true}
              ></i>
            </button>
          </div>
        </div>
        <div className="grid gap-16 columns-5-laptop-l margin-top-12">
          {renderRow(`Quantidade `, resource?.total_quantity?.toFixed(2).replace('.', ','))}
          {renderRow(`Unitário `, formatNumberToCurrency(resource.total_value / resource.total_quantity))}
          {renderRow('Total', formatNumberToCurrency(resource.total_value))}
          {renderRow('Porcentagem', `${resource.percentage}%`)}
          {renderRow('Porcentagem Acumulada', `${resource.totalPercentage.toFixed(2)}%`)}
        </div>
      </div>
    })
  }

  const download = (e) => {
    e.preventDefault();

    const fields = [
      { key: 'name', label: 'Descrição do ID' },
      { key: 'id', label: 'ID' },
      { key: 'measurement_unit', label: 'Unidade de medida' },
      { key: 'percentage', label: 'Porcentagem do ID' },
      { key: 'totalPercentage', label: 'Porcentagem total atual' },
      { key: 'evaluated_quantity', label: 'Quantidade Orçada' },
      { key: 'evaluated_total', label: 'Total Orçado' },
      { key: 'evaluated_indexed', label: 'Orçado Indexado' },
      { key: 'attended_quantity', label: 'Quantidade Apropriada' },
      { key: 'attended_total', label: 'Total Apropriado' },
      { key: 'attended_indexed', label: 'Apropriado Indexado' },
      { key: 'total_quantity', label: 'Quantidade Total' },
      { key: 'total_value', label: 'Valor total' },
    ];

    let csv = '';

    csv += fields.map(field => `"${field.label}"`).join(',') + '\r\n';

    const filteredResources = resources.map(i => {
      return {
        name: i.name.replaceAll(',', ' ').replaceAll(';', ' ').replaceAll('\t', ' '),
        ...i,
      };
    });

    filteredResources.forEach(resource => {
      const row = fields.map(field => `"${(resource[field.key] ?? '').toString().replace(/"/g, '""')}"`).join(',');
      csv += row + '\r\n';
    });

    const link = document.createElement('a');
    link.id = 'download-csv';
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    link.setAttribute('download', 'curvaABC.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      {loading && <div className="margin-top-24"><Loader /></div>}
      <div className="resources-container">
        <form>
          <div className="flex justify-content-space-between">
            <input style={{ width: '30%' }} type="text" value={searchString} placeholder="Buscar por insumo" onChange={handleSearchString} />
            <button className="button button-accent margin-bottom-8" id="search-resources-button" onClick={download}>Baixar .csv</button>
          </div>
        </form>
        {resources?.length > 0 && (
          <>
            <div className="accordion margin-top-24">
              {RenderRows()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Curve;
