import Modal from "@components/Modal";
import { useAuthContext } from "@contexts/auth";
import { useDashboardUIActions, useDashboardUIContext } from "@contexts/dashboardUI";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { formatDate } from "@utils/formatDate";
import { useState } from "react";
import Tooltip from "./tooltip";

const Header = () => {
  const { user } = useAuthContext()
  const { activeProject, companies, cubBased } = useProjectContext();
  const { toggleSidebar, toggleOffcanvas, toggleHelpModal } = useDashboardUIActions();
  const { checkQueue, updateCubBased } = useProjectActions();
  const { showSidebar } = useDashboardUIContext();
  const [modalVisible, setModalVisible] = useState(false)
  const [response, setResponse] = useState({
    done: false,
    ok: false,
    responseHead: '',
    responseText: '',
  })
  const [testToUpdate, setTestToUpdate] = useState({
    company_id: '',
    building_id: ''
  })

  const handleUpdate = async (type) => {
    const response = await checkQueue(activeProject?.company_id, type, activeProject?.building_name, user.email) //HARDCODED
    setResponse({
      done: true,
      ok: response.code === 200,
      responseHead: response.canBeUpdated ? 'Sucesso!' : 'Erro',
      responseText: response.message
    })
    return response
  }

  const handleModal = () => {
    setResponse({ ...response, done: false })
    setModalVisible(!modalVisible)
  }

  const sendToUpdate = async (e) => {
    const res = await handleUpdate(e);
    if (res.canBeUpdated === true) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Access-Control-Allow-Headers", "*");
      // myHeaders.append("Access-Control-Allow-Origin", "*");
      // myHeaders.append("Access-Control-Allow-Credentials", true);

      const raw = JSON.stringify({
        "companyId": activeProject?.company_id,
        "buildingId": activeProject?.building_id,
        "email": user.email
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`https://tfkc4dw672.execute-api.us-east-1.amazonaws.com/prod/exec-${e}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }
  }

  const currentCompany = companies?.filter(i => i._id === activeProject?.company_id)[0]

  return (
    <header className={showSidebar ? 'margin-left' : ''}>
      <hgroup className="flex gap-16 align-center">
        {!showSidebar && <button className="toggle-menu-button" onClick={toggleSidebar}>
          <i
            className="icon icon-width-24 icon-angle-double-right background-accent"
            aria-hidden={true}
          ></i>
        </button>}
        <h1>{activeProject?.building_name}</h1>
        {/* <button onClick={test}>ATUALIZAAAAAAAAAAAA</button> */}
        {modalVisible && <Modal close={handleModal}>
          <h3 className="update-title">ATUALIZAR DADOS</h3>
          <div className="close-icon" onClick={handleModal}>
            <i
              className="icon icon-width-24 icon-circle-x background-accent"
              aria-hidden={true}
            />
          </div>
          <p className="update-confirm">Tem certeza que deseja atualizar os dados dessa obra:</p>
          <span className="update-building">{activeProject?.building_name}</span>
          {currentCompany.updateLogs?.length > 0 && <p className="update-confirm">Última atualização feita: {formatDate(currentCompany.updateLogs?.slice(-1)[0].date)}</p>}
          {response.done && <div className={`alert alert-${response.ok ? "success" : "error"} margin-top-32`}>
            <h3 className="info-title">{response.responseHead}</h3>
            <p>{response.responseText}</p>
          </div>}
          {/* <input placeholder="company_id" name="company_id" value={testToUpdate.company_id} onChange={handleChangeTest} />
          <input placeholder="building_id" name="building_id" value={testToUpdate.building_id} onChange={handleChangeTest} /> */}
          <div className="flex gap-16 margin-top-24" style={{ justifyContent: 'space-around' }}>
            <button className="button button-accent" title="Atualização completa da obra" onClick={() => sendToUpdate('data-pipeline')} disabled={response.ok & response.done}>Completo</button>
            <button className="button button-accent" onClick={() => sendToUpdate('disbursement')} disabled={response.ok & response.done}>Desembolso</button>
            <button className="button button-accent" onClick={() => sendToUpdate('trend-stock')} disabled={response.ok & response.done}>Tendência</button>
            <button className="button button-accent" onClick={() => sendToUpdate('financial')} disabled={response.ok & response.done}>Financeiro</button>
          </div>
          {/* <h2 className="margin-top-48">BOTÃO FAKE PRA TESTE</h2>
          <div className="flex gap-16" style={{ justifyContent: 'space-around' }}>
            <button className="button button-accent" title="Atualização completa da obra" onClick={() => handleUpdate('data-pipeline')} disabled={response.ok & response.done}>Completo</button>
            <button className="button button-accent" onClick={() => handleUpdate('disbursement')} disabled={response.ok & response.done}>Desembolso</button>
            <button className="button button-accent" onClick={() => handleUpdate('trend-stock')} disabled={response.ok & response.done}>Tendência</button>
            <button className="button button-accent" onClick={() => handleUpdate('financial')} disabled={response.ok & response.done}>Financeiro</button>
          </div> */}
        </Modal>}
        <div className="header-left-container">
          <div className="lang-selector">
            <label class="switch btn-color-mode-switch">
              <input
                type="checkbox"
                name="color_mode"
                id="color_mode"
                value="1"
                onChange={() => updateCubBased(!cubBased)}
              />
              <label
                for="color_mode"
                data-on="CUB"
                data-off="R$"
                class="btn-color-mode-switch-inner"
              ></label>
            </label>
          </div>
          <button disabled={currentCompany?.updating} onClick={handleModal} className="update-button">
            {!currentCompany?.updating ? "ATUALIZAR" : "ATUALIZANDO"}
          </button>

          <button className="toggle-offcanvas" onClick={toggleOffcanvas}>
            <i
              className="icon icon-width-16 icon-ellipsis-v"
              aria-hidden={true}
            ></i>
          </button>
          <button className="open-helper-button" onClick={toggleHelpModal}>?</button>
        </div>

      </hgroup>
    </header>
  );
};

export default Header;