import { useAuthActions, useAuthContext } from "@contexts/auth";
import { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const AppContainer = () => {
  const { user, cookieChecked } = useAuthContext();
  const { checkAuth } = useAuthActions();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (cookieChecked) {
      if (!user && !pathname.includes("/access")) {
        navigate("/access")
      } else if (user && !pathname.includes("/dashboard")) {
        navigate("/dashboard");
      }
    }
  }, [user, cookieChecked])

  return <Outlet />
}

export default AppContainer;