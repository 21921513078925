
import { useCostsActions, useCostsContext } from "@contexts/costs";
import { indexes, itemGroups, measurementUnits } from "./components/utils";
import { useEffect, useState } from "react";

const defaultParam = {
  name: '',
  value: 0,
  group: '',
  index: '',
  unit: '',
  _id: 0,
}

const Params = () => {
  const { getParams, updateParams, getMeasurementUnits, getIndexes } = useCostsActions();
  const { currentBuilding } = useCostsContext();
  const [paramInfo, setParamInfo] = useState(defaultParam)
  const [params, setParams] = useState([])
  const [units, setUnits] = useState([])
  const [customIndexes, setCustomIndexes] = useState([])

  const listParams = async () => {
    const res = await getParams('')
    setParams(res.res)

    const un = await getMeasurementUnits()
    setUnits(un.res)

    const ind = await getIndexes()
    setCustomIndexes(ind.res)
  }

  useEffect(() => {
    listParams()
  }, [])

  const handleSend = async () => {
    const res = await updateParams(paramInfo)
    // console.log(paramInfo)
  }

  const handleEdit = (event) => {
    event.preventDefault();

    setParamInfo(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  }

  const handleParamEdit = (i) => {
    setParamInfo(params[i])
  }

  const reset = () => {
    setParamInfo(defaultParam)
  }

  return <div className="costs">
    <h2>Params</h2>
    <button onClick={reset}>Novo Parâmetro</button>
    <div className="grid columns-6-tablet gap-8 margin-bottom-16">
      <div>
        <label>Nome</label>
        <input type="text" onChange={handleEdit} value={paramInfo.name} placeholder="Nome" name="name" />
      </div>
      <div>
        <label>Valor</label>
        <input type="number" onChange={handleEdit} value={paramInfo.value} name="value" />
      </div>
      <div>
        <label>Grupo</label>
        <select name="group" value={paramInfo.group} onChange={handleEdit}>
          <option disabled value="">Selecione um grupo</option>
          {itemGroups.map(i => {
            return <option key={i}>{i}</option>
          })}
        </select>
      </div>
      <div>
        <label>Índice</label>
        <select name="index" value={paramInfo.index} onChange={handleEdit}>
          <option disabled value="">Selecione um índice</option>
          {[...indexes, ...customIndexes].map(i => {
            return <option key={i.field ?? i.name}>{i.label ?? i.name}</option>
          })}
        </select>
      </div>
      <div>
        <label>Unidade</label>
        <select name="unit" value={paramInfo.unit} onChange={handleEdit}>
          <option disabled value="">Selecione uma unidade</option>
          {units.map(i => {
            return <option key={i.name}>{i.name} - {i.description}</option>
          })}
        </select>
      </div>
      <button onClick={handleSend} className="button button-accent">Enviar</button>
    </div>
    <div className="margin-top-16">
      {params.length > 0 && params.map((i, index) => {
        return <div key={i.name} className="grid columns-6-tablet gap-8">
          <p>{i.name}</p>
          <p>{i.value}</p>
          <p>{i.group}</p>
          <p>{i.index}</p>
          <p>{i.unit}</p>
          <button onClick={() => handleParamEdit(index)}>Editar</button>
        </div>
      })}
    </div>
  </div>
}

export default Params