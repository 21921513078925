import {
  useDashboardUIActions,
  useDashboardUIContext,
} from "@contexts/dashboardUI";
import { useProjectContext } from "@contexts/projects";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { HelpModalText } from "./HelpModalText";

const HelpModal = () => {
  const { pathname } = useLocation();
  const [helpText, setHelpText] = useState({})
  const { showHelpModal } = useDashboardUIContext();
  const { toggleHelpModal } = useDashboardUIActions();

  useEffect(() => {
    setHelpText(HelpModalText(pathname.split('/')[2]))
  }, [pathname])


  return (
    <div className={showHelpModal ? "offcanvas active" : "offcanvas"}>
      <div className="offcanvas-container">
        <hgroup className="offcanvas-header">
          <h2>Ajuda</h2>
          <button onClick={toggleHelpModal}>
            <i
              className="icon icon-width-24 icon-times background-accent"
              aria-hidden={true}
            ></i>
          </button>
        </hgroup>
        <div className="offcanvas-content">
          <hr />
          <h3 className="help-header">Como são calculados o custo reprogramado e a tendência no relatório custo por nível</h3>
          <p>Custo reprogramado = Tendência - Valor realizado</p>
          <p>Tendência: A coluna da Tendência do custo deverá ser elaborada nos critérios a seguir:</p>
          <p><span className="text-bold">Se o % Realizado Físico for menor que 20%: </span>
            O Valor da Tendência de custo será igual ao Valor Orçado.</p>
          <p><span className="text-bold">Se o % Realizado Físico estiver entre 21% e 50%: </span>
            O Valor da Tendência de custo será = Valor Orçado + (Valor Acumulado Realizado - Valor Acumulado Medido).</p>
          <p><span className="text-bold">Se o % Realizado Físico for maior que 50%: </span>
            O valor da tendência será: Valor Realizado x {'[1 - (Valor Medido / Valor Orçado)]/(Valor Medido/Valor Orçado)'} + Valor Realizado.</p>
          <p>Em qualquer condição, se o Valor Total Comprometido for maior que o Valor da Tendência calculada nas condições acima, então o Valor da Tendência será o Valor Total Comprometido.</p>
          <p>Total Comprometido: A coluna do Total Comprometido da obra será a somatória de todos os valores comprometidos para o item, não importando a data informada no Período Final.</p>

          <hr />
          <h3 className="help-header">Orçado Indexado</h3>
          <p><span className="text-bold">{`Situação 1: Medido < 100% e Pago < Orçado: `}</span>
            Orçamento Indexado = (Saldo Indexado - Saldo) + Orçado
          </p>
          <p><span className="text-bold">{`Situação 2: Medido < 100% e Pago > Orçado: `}</span>
            Orçamento Indexado = Orçado Indexado
          </p>
          <p><span className="text-bold">{`Situação 3: Quando não foi orçado: `}</span>
            Orçamento Indexado = Pago
          </p>
          <p>Quando o medido já estava em 100% no início do acompanhamento da Ampli o orçado indexado fica igual ao orçamento base.</p>
          <p>Quando o Medido chega em 100% (para casos em que temos acompanhamento da ampli desde antes do fim do serviço) - a última indexação será realizada no último mês antes de atingir 100%, considerando as situações acima.</p>

          <p>Saldo = Orçado-Pago</p>
          <p>Saldo indexado = (Saldo/Cub Base)*Cub Atual</p>

          <hr />
          <h3 className="help-header">Saldo Comprometido</h3>
          <p>(Pago + a pagar + distorção de centro) - itens sem apropriação</p>
        </div>
      </div>
      {showHelpModal && <div onClick={toggleHelpModal} className="offcanvas-background" />}

    </div>
  );
};

export default HelpModal;
