import { renderCard } from "./defaults";

const MainCards = ({ item }) => {

  const checkFilteredPath = () => {
    if (window.location.pathname.includes('projecao-financeira')) return true;
    return false;
  }

  return <>
    <h3>Dados complementares</h3>
    <div className="grid gap-24 columns-2-tablet columns-3-laptop-l margin-top-24">
      {renderCard('Comprometido (R$)', item?.commited)}
      {renderCard('Pago (R$)', checkFilteredPath() ? item?.filteredPaid : item?.paid)}
      {renderCard('A pagar (R$)', checkFilteredPath() ? item?.filteredToPay : item?.toPay)}
      {renderCard('Orçado (R$)', item?.budget)}
      {renderCard('Medido (R$)', item?.measured)}
      {renderCard('Tendência (R$)', item?.trend)}
      {renderCard('IDC', item?.idc)}
      {renderCard('Valor não lançado (R$)', item?.extraValue?.value)}
      {renderCard('Comprometido + Não lançado (R$)', (item?.extraValue?.value + item?.commited))}
      {renderCard('Em Estoque (R$)', (item?.stockValue))}
      {renderCard('Tendência com Estoque (R$)', (item?.stockTrend))}
    </div>
  </>
}

export default MainCards;