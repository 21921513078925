import { formatNumberToCurrency } from "@utils/formatNumber"
import { renderRow } from "./defaults"
import { useState } from "react"
import { useProjectActions, useProjectContext } from "@contexts/projects"
import LoaderItem from "../value/accordion/LoaderItem";
import Tooltip from "../tooltip";


const SubAccord = ({ label, items }) => {
  const { selectedDetail: activeProject } = useProjectContext();

  const formatWbs = (str) => {
    if (str.length <= 3) return str;
    if (str.length <= 5) return `${str.slice(0, 3)}.${str.slice(3)}`;
    if (str.length <= 8) return `${str.slice(0, 3)}.${str.slice(3, 5)}.${str.slice(5)}`;
    if (str.length <= 11) return `${str.slice(0, 3)}.${str.slice(3, 5)}.${str.slice(5, 8)}.${str.slice(8, 11)}`;
    return `${str.slice(0, 3)}.${str.slice(3, 5)}.${str.slice(5, 8)}.${str.slice(8, 11)}.${str.slice(11)}`;
  };

  const checkStep = (step, value) => {
    if (!value) return ''
    return `${step}: ${value}`
  }

  return (
    <div className="resource-details-container">
      <h2>Itens {label}</h2>
      {items.map((i, idx) => (
        <div className="details" key={`${i.wbs_code}-${idx}`}>
          <span
            className="wbs-code flex"
            title={i.wbs ? `${checkStep('Unidade', i.wbs.unit)}\nCélula: ${i.wbs.cell}\nEtapa: ${i.wbs.step}\nSub-Etapa: ${i.wbs.substep}\n${checkStep('Serviço: ', i.wbs.service)}` : "Carregando..."}
          >
            {formatWbs(i.wbs_code.replace(activeProject.item.wbs_code_id_building, ""))} <Tooltip />
          </span>
          <div className="info grid columns-4-tablet margin-top-8 margin-left-8">
            {renderRow("Quantidade", i.quantity)}
            {renderRow("Valor Unitário", formatNumberToCurrency(i.value))}
            {renderRow("Valor Total", formatNumberToCurrency(i.total_value))}
            {renderRow("Valor Indexado", formatNumberToCurrency(i.indexed_value))}
          </div>
        </div>
      ))}
    </div>
  );
};

const ResourceAccordion = ({ resource, index }) => {
  const { selectedDetail: activeProject } = useProjectContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getWbsPath } = useProjectActions();
  const [attendedItems, setAttendedItems] = useState([]);
  const [evaluatedItems, setEvaluatedItems] = useState([]);

  const handleOpen = async () => {
    if (open) {
      setOpen(false);
      return;
    }

    setLoading(true);

    const updatedAttendedItems = await Promise.all(
      resource.attended_items.map(async (i) => {
        const res = await getWbsPath(
          i.wbs_code.replace(activeProject.item.wbs_code_id_building, ""),
          activeProject.item.wbs_code_id_building
        );
        return { ...i, wbs: res.response };
      })
    );

    const updatedEvaluatedItems = await Promise.all(
      resource.evaluated_items.map(async (i) => {
        const res = await getWbsPath(
          i.wbs_code.replace(activeProject.item.wbs_code_id_building, ""),
          activeProject.item.wbs_code_id_building
        );
        return { ...i, wbs: res.response };
      })
    );

    setAttendedItems(updatedAttendedItems);
    setEvaluatedItems(updatedEvaluatedItems);

    setLoading(false);
    setOpen(true);
  };

  return (
    <>
      <div key={index} className="accordion-item offcanvas-accordion-item">
        <div>
          <div className="flex align-center gap-8">
            <div className="grid">
              <span className="resource-id margin-bottom-0">Insumo - {resource?.id}</span>
              <div className="flex align-center margin-top-8">
                <button
                  className={`accordion-toggle ${open ? "active" : ""}`}
                  onClick={handleOpen}
                />
                <span className="resource-title">{resource.name} - {resource.measurement_unit}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-content columns-6-tablet margin-top-12">
          {renderRow("Quant. Orçada", resource.evaluated_items?.reduce((a, b) => a + b.quantity, 0).toFixed(2))}
          {renderRow("Média Orçado", formatNumberToCurrency(resource.evaluated_items?.reduce((a, b) => a + b.total_value, 0) / resource.evaluated_items?.reduce((a, b) => a + b.quantity, 0)))}
          {renderRow("Total Orçado", formatNumberToCurrency(resource.evaluated_items?.reduce((a, b) => a + b.total_value, 0)))}
          {renderRow("Quant. Apropriado", resource.attended_items?.reduce((a, b) => a + b.quantity, 0).toFixed(2))}
          {renderRow("Média Apropriado", formatNumberToCurrency(resource.attended_items?.reduce((a, b) => a + b.total_value, 0) / resource.attended_items?.reduce((a, b) => a + b.quantity, 0)))}
          {renderRow("Total Apropriado", formatNumberToCurrency(resource.attended_items?.reduce((a, b) => a + b.total_value, 0)))}
        </div>
      </div>
      {loading && <div className="margin-left-16"><LoaderItem /></div>}
      {open && !loading && (
        <>
          {evaluatedItems.length > 0 && <SubAccord label={"Orçados"} items={evaluatedItems} />}
          {attendedItems.length > 0 && <SubAccord label={"Apropriados"} items={attendedItems} />}
        </>
      )}
    </>
  );
};

export default ResourceAccordion;
