import { Accordion } from "../defaults";

const EapLevels = ({ response }) => {
  const hierarchy = {};

  response.forEach(item => {
    const { unit, cell, step, substep, service } = item;

    if (!hierarchy[unit]) {
      hierarchy[unit] = { title: unit, subs: {}, type: "Unidade" };
    }

    if (!hierarchy[unit].subs[cell]) {
      hierarchy[unit].subs[cell] = { title: cell, subs: {}, type: "Célula" };
    }

    if (!hierarchy[unit].subs[cell].subs[step]) {
      hierarchy[unit].subs[cell].subs[step] = { title: step, subs: {}, type: "Etapa" };
    }

    if (!hierarchy[unit].subs[cell].subs[step].subs[substep]) {
      hierarchy[unit].subs[cell].subs[step].subs[substep] = { title: substep, subs: [], type: "Subetapa" };
    }

    hierarchy[unit].subs[cell].subs[step].subs[substep].subs.push({ title: service, type: 'Serviço' });
  });

  const convertToArray = (obj) => {
    return Object.values(obj).map(item => {
      if (item.subs) {
        item.subs = convertToArray(item.subs);
      }
      return item;
    });
  }

  const renderSubs = (formatted) => {
    return formatted.map(item => (
      <Accordion key={item.title} item={item} count={response?.length} />
    ));
  };

  const formatted = convertToArray(hierarchy);

  const res = <div className="eap-container"><h3>Orçado por nível</h3>{renderSubs(formatted)}</div>

  return res;
}

export default EapLevels;