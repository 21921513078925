import DateRangeFilter from "@components/dashboard/DateRangeFilter";
import BasicInfo from "@components/dashboard/details/BasicInfo";
import FinancialBarChart from "@components/dashboard/financialProjection/FinancialBarChart";
import UnitAccordion from "@components/dashboard/financialProjection/accordion/UnitAccordion";
import { useDashboardUIContext } from "@contexts/dashboardUI";
import { useProjectContext } from "@contexts/projects";

const Projection = () => {

  const { barChartDate } = useDashboardUIContext()
  const { activeProject } = useProjectContext()
  if (!activeProject) return <></>
  return (
    <>
      <BasicInfo />

      <div className="grid gap-24 margin-top-40">
        <div>
          <hgroup className="dashboard-title flex wrap-wrap gap-16 align-center justify-content-space-between">
            <h2>Projeção Financeira</h2>
            <DateRangeFilter />
          </hgroup>
        </div>
        <div id="chart-bars">
          <FinancialBarChart graphSet="graphA" />
        </div>
      </div>
      {barChartDate && (
        <hgroup className="dashboard-title margin-top-24">
          <h2>Filtrando pela data {barChartDate}</h2>
        </hgroup>

      )}
      <UnitAccordion />

    </>

  );
};

export default Projection;
