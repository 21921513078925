const EapDisb = ({ item, resourceEAP }) => {
  return <div>
    <div className="disbursement-container">
      <h3>Desembolso</h3>
      <h2>Antecedência de Compra: {item.delivery_time ?? 0} dia(s)</h2>
      <br />
      <div className="disbursement-headers">
        <span>Dia</span>
        <span>Porcentagem</span>
      </div>
      <div className="disbursement-lines-container">
        {resourceEAP?.disbursement?.length > 0 ? resourceEAP.disbursement.map(i => {
          return <div className="disbursement-line">
            <p>{i.disbursement_day}</p>
            <p>{i.disbursement_percent}</p>
          </div>
        }) : <div className="disbursement-line">
          <p>01</p>
          <p>100</p>
        </div>}
      </div>
    </div>
    <br />
  </div>
}

export default EapDisb