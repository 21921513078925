import React from "react";

const Modal = ({ close, children }) => {

  return (
    <>
      <div className="modal-content">
        {children}
      </div >
      <div className="modal-background" onClick={close} />
    </>
  );
};

export default Modal;
