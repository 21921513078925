import { formatDateDMY } from "@utils/formatDate";
import { formatNumberToCurrency } from "@utils/formatNumber";
import React, { useState } from "react";


const DocumentAccordion = ({ item }) => {

  const [toggle, setToggle] = useState(false)

  const Item = ({ item }) => {
    return <div key={item.creditor_name} className="accordion-item">
      <div className="accordion-item-body">
        <div>
          <div className="flex align-center gap-8">
            <button
              className={`accordion-toggle ${toggle ? "active" : ""}`}
              onClick={() => setToggle(!toggle)}
            ></button>
            <div className="grid">
              <span className="accordion-label">Documento</span>
              <span className="accordion-title">{item.doc}</span>
            </div>
          </div>
        </div>
        <div className="margin-right-16">
          <span className="accordion-label">Valor</span>
          <span className="accordion-title">{formatNumberToCurrency(item.total_value)}</span>
        </div>
      </div>
      {toggle && item.items.sort((a, b) => b.value - a.value).map(i => {
        return <div key={i.id} className="accordion-item">
          <div className="accordion-item-body">
            <div>
              <div className="flex align-center gap-24">
                <div className="grid">
                  <span className="accordion-label">Título</span>
                  <span className="accordion-title">{i.id}</span>
                </div>
                <div className="grid">
                  <span className="accordion-label">Data</span>
                  <span className="accordion-title">{formatDateDMY(i.date)}</span>
                </div>
              </div>
            </div>
            <div className="margin-right-16">
              <span className="accordion-label">Valor</span>
              <span className="accordion-title">{formatNumberToCurrency(i.value)}</span>
            </div>
          </div>
        </div>
      })}
    </div>
  }

  return <Item item={item} />
}

export default DocumentAccordion;