import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import AccordionItem from "./AccordionItem";

const ServiceAccordion = ({parent, filters}) => {
  
  const {activeProject: {_id, services = {}}} = useProjectContext();
  const { loadServices } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadServices(parent);
    } 
  },[_id])

  const substepServices = useMemo(() => services[parent], [services, parent])

  return (
    <>
      {!substepServices && <LoaderItem />}
      {substepServices?.map((item, index) => <AccordionItem key={index} item={item} type={"service"} typeTitle={"Serviço"} filters={filters}/>)}
    </>
  )
}

export default ServiceAccordion;