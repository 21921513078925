import { useProjectContext } from "@contexts/projects";
import IndicatorCard from "./IndicatorCard";
import { cardTexts } from "@utils/cardTexts";

const Indicators = () => {
  const { activeProject: { indicator, lastMeasure } } = useProjectContext();

  return (

    <div>
      <div className="grid columns-3 columns-1-laptop gap-16">
        <IndicatorCard title="Orçado Indexado/ Tendência (%)" tooltip={cardTexts.TREND_BUDGETED} indicator={indicator?.ioit} />
        {/* <IndicatorCard title="IDP (%)" indicator={lastMeasure ? lastMeasure.measured_accumulated_percentage / lastMeasure.baseline_accumulated_percentage : 0} /> */}
        <IndicatorCard title="IDC (%)" tooltip={cardTexts.IDC} indicator={indicator?.idc} />
        <IndicatorCard title="Percentual Apropriado" tooltip={cardTexts.APPROPRIATED} black indicator={indicator?.ipa} />
      </div>
    </div>
  )
}

export default Indicators;