import { Link } from "react-router-dom";

import Loader from "@components/Loader";
import { useAuthActions, useAuthContext } from "@contexts/auth";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ResetPasswordRequest = () => {
  const { alphaKeyEmail } = useAuthContext();
  const { completeRegistration } = useAuthActions();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    if(!alphaKeyEmail) {
      navigate("/access")
    }
  },[])

  const submitForm = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true)
    await completeRegistration({name, password})
    navigate("/dashboard")
  }, [name, password])


  return (
    <form id="access-form">
      {isLoading && <Loader />}

      <hgroup>
        <h1>Cadastro</h1>
        <h2 className="margin-top-16">
          Você está criando o cadastro para o e-mail {alphaKeyEmail}. Para continuar seu cadastro, preencha os campos abaixo com suas
          informações. Você poderá editar informações adicionais em seu perfil
          após o termino dessa etapa.
        </h2>
      </hgroup>
      <fieldset className="grid gap-16 margin-top-32">
        <label>
          <span>Nome completo</span>
          <input
            type="text"
            name={"name"}
            value={name}
            onChange={({target: {value}}) => setName(value)}
            required={true}
          />
        </label>
        <label>
          <span>Password</span>
          <input
            type="password"
            name={"password"}
            value={password}
            onChange={({target: {value}}) => setPassword(value)}
            required={true}
          />
        </label>
      </fieldset>
      <p className="text-small margin-top-32">
        Mantenha sua conta segura usando uma senha forte. Incluir letras
        maísculas e minúsculas, bem como números e símbolos, pode ajudar a tonar
        uma senha mais forte.
      </p>
      <div className="flex align-center justify-content-space-between gap-16 wrap-wrap margin-top-32">
        <Link to="/access" className="text-small">
          Voltar
        </Link>
        <button onClick={submitForm} className="button button-accent">Continuar</button>
      </div>
    </form>
  );
};

export default ResetPasswordRequest;
