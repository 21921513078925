import React, { useState, useEffect } from "react";
import { useBimActions } from "@contexts/bim";

const defaultOption = { name: "", type: "" };

const Options = () => {
  const { addOptionAction } = useBimActions();
  const [options, setOptions] = useState([]);
  const [optionInfo, setOptionInfo] = useState(defaultOption);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOptions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/getOptions`, { method: "GET" });
      const data = await response.json();

      if (data?.res && Array.isArray(data.res)) {
        setOptions(data.res);
      } else {
        throw new Error("Dados não recebidos corretamente.");
      }
    } catch (error) {
      console.error("Erro ao buscar opções:", error);
      setError("Erro ao carregar as opções.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleSend = async () => {
    if (!optionInfo.name || !optionInfo.type) {
      alert("Preencha todos os campos antes de enviar.");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const result = await addOptionAction(optionInfo);

      if (result.success) {
        fetchOptions();
        setOptionInfo(defaultOption);
      } else {
        throw new Error(result.message || "Erro ao adicionar a opção.");
      }
    } catch (error) {
      console.error("Erro ao adicionar a opção:", error);
      setError(error.message || "Erro ao adicionar a opção.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setOptionInfo((prev) => ({ ...prev, [name]: value }));
  };

  const reset = () => {
    setOptionInfo(defaultOption);
  };

  const groupedOptions = options.reduce((acc, option) => {
    const group = acc[option.type] || [];
    group.push(option);
    acc[option.type] = group;
    return acc;
  }, {});

  return (
    <div className="costs">
      <h2>Gerenciamento de Opções</h2>

      {/* Formulário para adicionar nova opção */}
      <div className="form-container margin-bottom-24">
        {/* <button onClick={reset} className="grid columns-6-tablet gap-8 margin-bottom-16">
          Nova Opção
        </button> */}
        <div className="grid columns-6-tablet gap-8 margin-bottom-16">
          <div>
            <label>Nome</label>
            <input
              type="text"
              name="name"
              value={optionInfo.name}
              onChange={handleEdit}
              placeholder="Nome"
            />
          </div>
          <div>
            <label>Tipo</label>
            <select
              name="type"
              value={optionInfo.type}
              onChange={handleEdit}
            >
              <option value="" disabled>Selecione um tipo</option>
              <option value="Disciplina">Disciplina</option>
              <option value="Tipologia">Tipologia</option>
              <option value="Verificação">Verificação</option>
              <option value="Etapa">Etapa</option>
              <option value="Ação">Ação</option>
            </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button onClick={handleSend} className="button button-accent">
              {loading ? "Adicionando..." : "Enviar"}
            </button>
          </div>
        </div>
      </div>

      {/* Lista de opções */}
      <div className="options-list">
        <h3>Opções cadastradas</h3>
        {loading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : Object.keys(groupedOptions).length > 0 ? (
          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
            {Object.keys(groupedOptions).map((type) => (
              <div key={type} style={{ flex: '1 1 20%', margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}>
                <h4 style={{ fontWeight: 'bold', fontSize: '16px' }}>{type}</h4>
                {groupedOptions[type].map((option) => (
                  <div key={option._id} style={{ marginBottom: '8px' }}>
                    {option.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <p>Nenhuma opção encontrada.</p>
        )}
      </div>



    </div>
  );
};

export default Options;
