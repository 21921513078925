import { useCostsActions } from "@contexts/costs";
import { useEffect, useState } from "react";


const Indexes = () => {
  const { getIndexes, updateIndex } = useCostsActions();
  const [newIndex, setNewIndex] = useState('')
  const [indexes, setIndexes] = useState([])

  const getIndex = async () => {
    const res = await getIndexes()
    setIndexes(res.res)
  }

  const handleSend = async () => {
    const res = await updateIndex(newIndex)
    if (res.code === 200) {
      setIndexes(prev => ([...prev, { name: newIndex }]))
      setNewIndex('')
    }
  }

  useEffect(() => {
    getIndex()
  }, [])

  return <div className="costs">
    <h2>Novo Índice</h2>
    <div className="grid columns-3-tablet gap-16">
      <input value={newIndex} onChange={(e) => setNewIndex(e.target.value)} />
      <button onClick={handleSend} className="button-accent">Enviar</button>
    </div>
    <h2>Índices</h2>
    {indexes.map(i => {
      return <p>{i.name}</p>
    })}
  </div>
}

export default Indexes;