import { Link } from "react-router-dom";

import Loader from "@components/Loader";

const PasswordCreation = () => {
  const isLoading = false;

  return (
    <form id="access-form">
      {isLoading && <Loader />}

      <hgroup>
        <h1>Definir senha</h1>
        <h2 className="margin-top-16">
          Mantenha sua conta segura usando uma senha forte. Incluir letras
          maísculas e minúsculas, bem como números e símbolos, pode ajudar a
          tonar uma senha mais forte.
        </h2>
      </hgroup>
      <fieldset className="grid gap-16 margin-top-32">
        <label>
          <span>Crie uma senha</span>
          <input
            type="email"
            name={""}
            value={""}
            onChange={() => {}}
            required={true}
          />
        </label>
        <label>
          <span>Repita a senha</span>
          <input
            type="email"
            name={""}
            value={""}
            onChange={() => {}}
            required={true}
          />
        </label>
      </fieldset>
      <div className="flex align-center justify-content-space-between gap-16 wrap-wrap margin-top-32">
        <Link to="/access" className="text-small">
          Voltar
        </Link>
        <button className="button button-accent">Continuar</button>
      </div>
    </form>
  );
};

export default PasswordCreation;
