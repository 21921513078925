
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { useAuthActions } from "@contexts/auth";
import { useEffect, useState } from "react";

const NewBuilding = () => {
  const [newBuilding, setNewBuilding] = useState({})
  const [companies, setCompanies] = useState([])
  const { listCompanies, createBuilding } = useProjectActions();

  const getCompanies = async () => {
    const res = await listCompanies();
    setCompanies(res.companies)
  }

  useEffect(() => {
    getCompanies();
  }, [])

  const handleInput = (e) => {
    if (e.target.type === 'number') {
      setNewBuilding(prev => ({ ...prev, [e.target.name]: +e.target.value }))
    }
    else {
      setNewBuilding(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
  }

  const handleSend = (e) => {
    e.preventDefault()
    const formattedBuilding = {
      ...newBuilding,
      start_date: new Date(newBuilding.start_date),
      end_date: new Date(newBuilding.end_date),
      budget_delivery_date: new Date(newBuilding.budget_delivery_date),
      indexer: {
        description: newBuilding.indexer_description,
        type: newBuilding.indexer_type,
        uf: newBuilding.indexer_uf,
      }
    }
    delete formattedBuilding.indexer_description
    delete formattedBuilding.indexer_type
    delete formattedBuilding.indexer_uf
    createBuilding(formattedBuilding)
  }

  const getLabel = (i) => {
    const words = i.split("_");
    return words.map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    }).join(" ");

  }

  const fields = [{
    name: 'building_code',
    type: 'number',
  }, {
    name: 'company_code',
    type: 'text',
  }, {
    name: 'building_name',
    type: 'text',
  }, {
    name: 'start_date',
    type: 'date',
  }, {
    name: 'end_date',
    type: 'date',
  }, {
    name: 'area',
    type: 'number',
  }, {
    name: 'budget_version',
    type: 'number',
  }, {
    name: 'budget_delivery_date',
    type: 'date',
  }, {
    name: 'indexer_description',
    type: 'text',
  }, {
    name: 'indexer_type',
    type: 'text',
  }, {
    name: 'indexer_uf',
    type: 'text',
  }, {
    name: 'building_constructive_units_id',
    type: 'text',
  }, {
    name: 'cost_centers_id',
    type: 'text',
  }, {
    name: 'building_cost_centers_id',
    type: 'text',
  }, {
    name: 'appropriation_level',
    type: 'select',
    options: ['service', 'substep', 'constructivecell']
  }, {
    name: 'plan_tool',
    type: 'select',
    options: ['prevision', 'project']
  }, {
    name: 'scope',
    type: 'select',
    options: ['mc', 'integration']
  }
  ]

  return (
    <>
      <div>
        <form className="new-building-container">
          <div>
            <label>Company</label>
            <select name="company_id" defaultValue='choose' onChange={handleInput}>
              <option disabled value='choose'>Choose</option>
              {companies.map(i => {
                return <option key={i._id} value={`${i._id}`}>{i.name}</option>
              })}
            </select>
          </div>
          {fields.map(i => {
            if (i.type === 'select') {
              return <div key={i.name}>
                <label>{getLabel(i.name)}</label>
                <select name={i.name} defaultValue='choose' onChange={handleInput}>
                  <option disabled value='choose'>Choose</option>
                  {i.options.map(j => {
                    return <option key={j} value={j}>{j}</option>
                  })}
                </select>
              </div>
            } else {
              return <div key={i.name}>
                <label>{getLabel(i.name)}</label>
                <input type={i.type} name={i.name} onChange={handleInput} value={newBuilding[i.name]} />
              </div>
            }
          })}
          <button className="button button-accent" onClick={handleSend}>Enviar</button>
        </form>
      </div>
    </>
  );
};

export default NewBuilding;
