import React, { useMemo, useState } from "react";
import { formatNumberToCurrency } from "@utils/formatNumber";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import LoaderItem from "../value/accordion/LoaderItem";
import DetailsAccordion from "./DetailsAccordion";
import DetailsGraph from "./DetailsGraph";

const NewDataHandler = ({ max, from, name }) => {
  const { updateAdvancement } = useProjectActions()
  const { activeProject } = useProjectContext()
  const [newData, setNewData] = useState({
    max: max,
    from: from,
    value: '',
    to: '',
    description: '',
  })
  const { building_id } = activeProject
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({
    code: 0,
    msg: ''
  })

  const formatCurrency = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const numericValue = parseFloat(cleanedValue) / 100;
    if (numericValue > max) {
      return max.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    }
    return numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    if (inputValue.length === 0) {
      setNewData(prev => ({ ...prev, value: "" }));
    } else {
      setNewData(prev => ({ ...prev, value: formatCurrency(inputValue) }));
    }
  };

  const handleSend = async () => {
    // if (!newData.value) return 0;
    const cleanedString = newData.value
      .replace(/[R$.\s]/g, "")
      .replace(",", ".");
    const numericValue = parseFloat(cleanedString);
    const { description, to, from } = newData
    setLoading(true)
    const res = await updateAdvancement(name, numericValue, description, to, from, building_id)
    setResponse(res)
    setLoading(false)
  }

  const handleToChange = (e) => {
    const { value } = e.target
    setNewData(prev => ({ ...prev, to: `${value.slice(5, 7)}/${value.slice(0, 4)}` }))
  }

  const handleDescChange = (e) => {
    setNewData(prev => ({ ...prev, description: e.target.value }))
  }

  const checkDisabled = () => {
    return (newData.value === '' || newData.to === '' || loading)
  }


  return <>
    <p>Adiantar valores do mês {from}:</p>
    <div className="advancement-form-container">
      <div>
        <p>Selecione o valor que deseja adiantar:</p>
        <input
          type="text"
          max={newData.max}
          value={newData.value}
          onChange={handleInputChange}
          placeholder={`Máx.: ${formatNumberToCurrency(newData.max)}`}
        />
      </div>
      <div>
        <p>Selecione o mês para o qual deseja adiantar:</p>
        <input type='month' onChange={handleToChange} />
      </div>
      <div>
        <p>Descrição do adiantamento (Opcional): </p>
        <input type='text' onChange={handleDescChange} />
      </div>
    </div>
    <div className="flex direction-column justify-content-end">
      <button className={`button button-${checkDisabled() ? 'grey' : 'accent'} margin-bottom-16 margin-top-8`} disabled={checkDisabled()} onClick={handleSend}>Enviar</button>
      {response.code === 200 && <p style={{ textAlign: 'end' }}>Adiantamento criado!</p>}
      {response.code === 500 && <p style={{ textAlign: 'end' }}>Erro, tente novamente</p>}
    </div>
  </>
}


const AdvancementAccordion = ({ item }) => {
  const { getAppropriation, getAdvancements, updateAdvancement } = useProjectActions();
  const { activeProject } = useProjectContext()
  const [toggle, setToggle] = useState(false)
  const [items, setItems] = useState([])
  const [status, setStatus] = useState({
    finished: false,
    searched: false
  })
  const [groupedMonths, setGroupedMonths] = useState([])
  const [transferData, setTransferData] = useState({
    from: '',
    to: '',
    max: 0,
    amount: 0
  })
  const [advancements, setAdvancements] = useState([])

  const getItems = async () => {
    if (toggle) {
      setToggle(false)
      setStatus({ finished: false, searched: false })
      return
    }
    setToggle(true)
    setStatus({ ...status, searched: true })
    const response = await getAppropriation(activeProject?.appropriation_level, activeProject?.wbs_code_id, item)
    const adv = await getAdvancements(item, activeProject.building_id)
    setAdvancements([...adv.response])
    setItems([...response.items])
    setStatus(prev => { return { ...prev, finished: true } })
    let months = [];
    response.items.forEach(i => {
      i.disbursements.forEach(j => {
        const idx = months.findIndex(k => k.year === j.year && k.month === j.month);
        if (idx === -1) {
          months.push({ ...j });
        } else {
          months[idx].value += j.value;
        }
      });
    });
    months.sort((a, b) => {
      if (a.year !== b.year) {
        return a.year - b.year;
      } else {
        return a.month - b.month;
      }
    });
    setGroupedMonths(months)
  }


  const handleSelectMonth = (event, itemsClicked) => {
    if (!event.chart.tooltip.dataPoints) return
    if (itemsClicked) {
      const { label, raw: maxValue } = event.chart.tooltip.dataPoints[0]
      setTransferData(prev => { return { ...prev, from: label, max: maxValue } })
    }
  }

  const memoizedGraph = useMemo(() => (
    <DetailsGraph data={groupedMonths} onClick={handleSelectMonth} />
  ), [groupedMonths]);

  const handleDelete = async (idx, to, from) => {
    await updateAdvancement(item, 0, '', to, from, activeProject.building_id)

    setAdvancements(prevAdvancements => {

      const updatedData = [...prevAdvancements[0].data];
      updatedData.splice(idx, 1);

      return [{ ...prevAdvancements[0], data: updatedData }];
    });
  };

  const Item = ({ item }) => {
    return <div key={item.creditor_name} className="accordion-item">
      <div className="accordion-item-body">
        <div>
          <div className="flex align-center gap-8">
            <button
              className={`accordion-toggle ${toggle ? "active" : ""}`}
              onClick={getItems}
            ></button>
            <div className="grid">
              <span className="accordion-title">{item}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="margin-left-16 advancement-infos-container">
        {(toggle && status.searched && status.finished && items.length === 0) && <p>Sem datas para adiantamneto</p>}
        {(toggle && items.length === 0 && status.searched && !status.finished) && <LoaderItem />}
        {(toggle && advancements[0]?.data.length > 0 && advancements[0]?.data.map((i, idx) => {
          return <div className="existing-advancements">
            <div>
              <p className="label">Valor:</p>
              <p className="value">{formatNumberToCurrency(i.value)}</p>
            </div>
            <div>
              <p className="label">Do mês:</p>
              <p className="value">{i.from}</p>
            </div>
            <div>
              <p className="label">Para o mês:</p>
              <p className="value">{i.to}</p>
            </div>
            <button style={{ alignSelf: 'auto' }} onClick={() => handleDelete(idx, i.to, i.from)}>Deletar</button>
          </div>
        }))}
        {(toggle && items.length > 0) && <div>
          <div style={{ height: '250px', maxWidth: '85vw' }}>
            {memoizedGraph}
          </div>
          {transferData.from !== '' && <NewDataHandler max={transferData.max} from={transferData.from} name={item} />}
          <h3>Distribuição por insumo/mês:</h3>
          <div className="advancement-items">
            {items.map(i => {
              return <DetailsAccordion item={i} select={() => handleSelect(i)} />
            })}
          </div>
        </div>}
      </div>
    </div>
  }

  return <Item item={item} />
}

export default AdvancementAccordion;