
import { useProjectActions, useProjectContext } from "@contexts/projects";
import { useAuthActions } from "@contexts/auth";
import { useEffect, useState } from "react";

const NewCompany = () => {
  const [newCompany, setNewCompany] = useState({
    name: '',
    subdomain: '',
    api_user: '',
    api_pass: '',
    api_limits: '',
  })
  const { createCompany } = useProjectActions();

  const handleInput = (e) => {
    setNewCompany(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSend = async (e) => {
    e.preventDefault()
    const limits = newCompany.api_limits.split('/')
    const company = { ...newCompany, api_limit_bulk: +limits[0], api_limit_rest: +limits[1] }
    const create = await createCompany(company)
  }

  return (
    <>
      <div>
        <form className="new-user-container">
          <label>Nome</label>
          <input type="text" name='name' onChange={handleInput} value={newCompany.name} />
          <label>Subdomain</label>
          <input type="text" name='subdomain' onChange={handleInput} value={newCompany.subdomain} />
          <label>Api User</label>
          <input type="text" name='api_user' onChange={handleInput} value={newCompany.api_user} />
          <label>Api Pass</label>
          <input type="text" name='api_pass' onChange={handleInput} value={newCompany.api_pass} />
          <label>Api Limits (Rest/Bulk)</label>
          <select name="api_limits" onChange={handleInput}>
            <option value='100/10'>100/10</option>
            <option value='1000/20'>1000/20</option>
            <option value='2500/50'>2500/50</option>
            <option value='5000/100'>5000/100</option>
            <option value='10000/200'>10000/200</option>
            <option value='75000/28800'>75000/28800</option>
          </select>
          <button disabled={newCompany.name === '' || newCompany.subdomain === "" || newCompany.api_user === "" || newCompany.api_pass === ""} className="button button-accent" onClick={handleSend}>Enviar</button>
        </form>
      </div>
    </>
  );
};

export default NewCompany;
