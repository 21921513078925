import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import Footer from "@components/dashboard/Footer";
import Loader from "@components/Loader";
import Sidebar from "@components/dashboard/Sidebar";

import ProjectProvider, { useProjectActions, useProjectContext } from "@contexts/projects";
import {
  useDashboardUIActions,
  useDashboardUIContext,
} from "@contexts/dashboardUI";
import ItemDetailOffcanvas from "@components/dashboard/ItemDetailOffcanvas";
import Offcanvas from "@components/dashboard/Offcanvas";
import Header from "@components/dashboard/Header";
import HelpModal from "@components/HelpModal";

const Dashboard = () => {
  const { isLoading, showSidebar } = useDashboardUIContext();
  const { projects, activeProject } = useProjectContext();
  const { setIsLoading } = useDashboardUIActions();
  const { list: listProjects, selectProject } = useProjectActions();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const paths = ['/backoffice/', '/costs/', '/bim/']

  useEffect(() => {
    (async () => {

      const projectId = pathname.split("/").pop();
      setIsLoading(true);
      await listProjects(projectId);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!projects?.length) return;
    // if (paths.filter(i => pathname.includes(i)).length !== 0) return;

    const projectId = pathname.split("/").pop();
    // const projectId = '66eacb4976d7c7909b0e9a91' //HARDCODED
    if (paths.some(substring => pathname.includes(substring))) return
    if (!projects.find(({ _id }) => _id === projectId)) {
      navigate(`${pathname}/${activeProject?._id}`)
    }
  }, [projects])

  useEffect(() => {
    const projectId = pathname.split("/").pop();
    if (paths.some(substring => pathname.includes(substring))) return
    if (activeProject && activeProject?._id != projectId) {
      selectProject(projectId)
    }
  }, [pathname])


  return (
    <section id="dashboard">
      {isLoading && <Loader />}

      {paths.filter(i => pathname.includes(i)).length === 0 && <Header />}

      <Sidebar />

      <main className={showSidebar ? 'padding-left' : ''}>
        <Outlet />
      </main>

      <Footer />

      <HelpModal />

      <Offcanvas />
      <ItemDetailOffcanvas />

    </section>
  );
};

export default Dashboard;
