import state from "./state.js";
import actions from "./actions.js";
import reducer from "./reducer.js";
import createContext from "../createContext.js";
import { useContext } from "react";

const {
  Context: projectContext,
  Provider: ProjectProvider,
  actions: processedActions,
} = createContext(state, actions, reducer);

export const useProjectContext = () => {
  return useContext(projectContext);
};

export const useProjectActions = () => {
  return processedActions();
};

export default ProjectProvider;
