export default (state, action) => {
  switch (action.type) {
    case "FOUND_PROJECTS":
      return {
        ...state,
        projects: action.data.projects,
        companies: action.data.companies
      }
    case "SELECT_PROJECT":
      return {
        ...state,
        activeProject: action.data
      }
    case "UPDATE_FOLLOW_UPS":
      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          followUps: action.data.followUps,
          paidValues: action.data.paidValues,
          disbursements: action.data.disbursements,
          toPayValues: action.data.toPayValues,
          toReceiveBills: action.data.toReceiveBills,
          receivedBills: action.data.receivedBills,
          takenTo: action.data.takenTo,
          takenFrom: action.data.takenFrom,
          accumulatedDisbursement: action.data.accumulatedDisbursement,
          accumulatedPaid: action.data.accumulatedPaid
        }
      }
    case "CHANGE_FILTER_DATES":
      return {
        ...state,
        filterDates: {
          beginAt: action.data.begin,
          endAt: action.data.end,
        }
      }
    case "LOADED_UNITS":
      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          units: action.data.units
        }
      }
    case "LOADED_CELLS":
      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          cells: {
            ...(state.activeProject.cells ?? {}),
            [action.data.wbs_code]: action.data.cells
          }
        }
      }
    case "LOADED_STEPS":
      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          steps: {
            ...(state.activeProject.steps ?? {}),
            [action.data.wbs_code]: action.data.steps
          }
        }
      }
    case "LOADED_SUBSTEPS":
      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          substeps: {
            ...(state.activeProject.substeps ?? {}),
            [action.data.wbs_code]: action.data.substeps
          }
        }
      }
    case "LOADED_SERVICES":
      return {
        ...state,
        activeProject: {
          ...state.activeProject,
          services: {
            ...(state.activeProject.services ?? {}),
            [action.data.wbs_code]: action.data.services
          }
        }
      }
    case "SELECTED_DETAIL":
      return {
        ...state,
        selectedDetail: action.data
      }
    case "UPDATE_CURVE_ID":
      return {
        ...state,
        curveID: action.data
      }
    case "UPDATE_RESOURCE_EAP":
      return {
        ...state,
        resourceEAP: action.data,
      }
    case "UPDATE_CUB_BASED":
      return {
        ...state,
        cubBased: action.data,
      }
    // case "CREATED":
    //   return {
    //     project: {
    //       id: action.data,
    //     },
    //   };
    // case "LIST_LOADED":
    //   return {
    //     projects: action.data,
    //     finishLoad: true,
    //     step: 0,
    //   };
    // case "PROJECT_LOADED":
    //   return {
    //     step: state.step,
    //     project: action.data,
    //   };
    // case "NEXT_STEP":
    //   return {
    //     ...state,
    //     step: state.step + 1,
    //   };
    // case "PREVIOUS_STEP":
    //   return {
    //     ...state,
    //     step: state.step + 1,
    //   };
    // case "SET_STEP":
    //   return {
    //     ...state,
    //     step: action.data,
    //   };
    default:
      return state;
  }
};
