const UserRow = ({ user, edit, reset }) => {
  const { name, type, email } = user;
  return <div className="user-row">
    <span>{name}</span>
    <span>{email}</span>
    <span>{type}</span>
    <button onClick={edit}>Editar</button>
    <button onClick={reset}>Resetar senha</button>
  </div>
}

export default UserRow;