import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import AccordionItem from "./AccordionItem";
import { useDashboardUIContext } from "@contexts/dashboardUI";

const ServiceAccordion = ({parent}) => {
  
  const { barChartDate } = useDashboardUIContext()
  const {activeProject: {_id, services = {}}, filterDates} = useProjectContext();
  const { loadServices } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadServices(parent, barChartDate);
    } 
  },[_id, filterDates, barChartDate])

  const substepServices = useMemo(() => services[parent], [services, parent])

  return (
    <>
      {!substepServices && <LoaderItem />}
      {substepServices?.map((item, index) => <AccordionItem key={index} item={item} type={"service"} typeTitle={"Serviço"}/>)}
    </>
  )
}

export default ServiceAccordion;