import { useCostsActions } from "@contexts/costs";
import { useEffect, useState } from "react";


const MeasurementUnits = () => {
  const { getMeasurementUnits, updateMeasurementUnits } = useCostsActions();
  const [newUnit, setNewUnit] = useState({ name: '', description: '' })
  const [units, setUnits] = useState([])

  const getUnits = async () => {
    const res = await getMeasurementUnits()
    setUnits(res.res)
  }

  const handleSend = async () => {
    const res = await updateMeasurementUnits(newUnit)
    if (res.code === 200) {
      setUnits(prev => ([...prev, newUnit]))
      setNewUnit({ name: '', description: '' })
    }
  }

  useEffect(() => {
    getUnits()
  }, [])

  return <div className="costs">
    <h2>Nova Unidade</h2>
    <div className="grid columns-3-tablet gap-16">
      <input value={newUnit.name} placeholder="Nome" onChange={(e) => setNewUnit({ ...newUnit, name: e.target.value })} />
      <input value={newUnit.description} placeholder="Descrição" onChange={(e) => setNewUnit({ ...newUnit, description: e.target.value })} />
      <button onClick={handleSend} className="button-accent">Enviar</button>
    </div>
    <h2>Unidades</h2>
    {units.map(i => {
      return <p>{i.name} - {i.description}</p>
    })}
  </div>
}

export default MeasurementUnits;