import React, { useState } from "react";
import { formatNumberToLocale } from "@utils/formatNumber";

const InnerAccordion = ({ item }) => {

  const [toggle, setToggle] = useState(false)

  const Item = ({ item }) => {
    return <div key={item.creditor_name} className="accordion-item margin-left-16">
      <div className="contracts-accordion-body">
        <div className="flex gap-8 align-center">
          <button
            className={`accordion-toggle ${toggle ? "active" : ""}`}
            onClick={() => setToggle(!toggle)}
          />
          <div>
            <span className="accordion-label">Descrição</span>
            <span className="accordion-title">{item?.description}</span>
          </div>
        </div>
        <div className="grid">
          <span className="accordion-label">Total do Contrato (R$)</span>
          <span className="accordion-title">{formatNumberToLocale(item?.total_labor_value)}</span>
        </div>
        <div className="grid">
          <span className="accordion-label">Quantidade Medida</span>
          <span className="accordion-title">{item?.total_quantity}</span>
        </div>
        <div className="grid">
          <span className="accordion-label">Total Medido (R$)</span>
          <span className="accordion-title">{formatNumberToLocale(item?.total_measured_value)}</span>
        </div>
      </div>
      {toggle && item.items.map(i => {
        return <div key={i.id} className="accordion-item margin-left-16">
          <div className="contracts-accordion-body">
            {/* <div className="grid">
              <span className="accordion-label">ID do Recurso</span>
              <span className="accordion-title">{i?.resource_id}</span>
            </div> */}
            <div className="grid">
              <span className="accordion-label">Fornecedor - {i?.supplier_id}</span>
              <span className="accordion-title">{i?.supplier_name}</span>
            </div>
            <div className="grid">
              <span className="accordion-label">Total do Contrato (R$)</span>
              <span className="accordion-title">{formatNumberToLocale(i?.total_labor_value.toFixed(2))}</span>
            </div>
            <div className="grid">
              <span className="accordion-label">Quantidade Medida</span>
              <span className="accordion-title">{i?.quantity}</span>
            </div>
            <div className="grid">
              <span className="accordion-label">Total Medido (R$)</span>
              <span className="accordion-title">{formatNumberToLocale(i?.measured_value.toFixed(2))}</span>
            </div>
          </div>
        </div>
      })}
    </div>
  }

  return <Item item={item} />
}

const ContractAccordion = ({ item }) => {

  const [toggle, setToggle] = useState(false)

  const Item = ({ item }) => {
    return <div key={item.creditor_name} className="accordion-item">
      <div className="contracts-accordion-body">
        <div className="flex gap-8 align-center">
          <button
            className={`accordion-toggle ${toggle ? "active" : ""}`}
            onClick={() => setToggle(!toggle)}
          />
          <div>
            <span className="accordion-label">Número do Contrato</span>
            <span className="accordion-title">{item?.contract_number}</span>
          </div>
        </div>
        <div className="grid">
          <span className="accordion-label">Total do Contrato (R$)</span>
          <span className="accordion-title">{formatNumberToLocale(item?.total_labor_value)}</span>
        </div>
        <div className="grid">
          <span className="accordion-label">Quantidade Medida</span>
          <span className="accordion-title">{item?.total_quantity}</span>
        </div>
        <div className="grid">
          <span className="accordion-label">Total Medido (R$)</span>
          <span className="accordion-title">{formatNumberToLocale(item?.total_measured_value)}</span>
        </div>
      </div>
      {toggle && item?.items?.map((i, idx) => <InnerAccordion key={i.total_labor_value} item={i} />)}
    </div>
  }

  return <Item item={item} />
}

export default ContractAccordion;