import React, { useState } from "react";
import DocumentAccordion from "./documentAccordion";
import { formatNumberToCurrency } from "@utils/formatNumber";


const CreditorAccordion = ({ item }) => {

  const [toggle, setToggle] = useState(false)

  const Item = ({ item }) => {
    return <div key={item.creditor_name} className="accordion-item">
      <div className="accordion-item-body">

        <div>
          <div className="flex align-center gap-8">
            <button
              className={`accordion-toggle ${toggle ? "active" : ""}`}
              onClick={() => setToggle(!toggle)}
            ></button>
            <div className="grid">
              <span className="accordion-label">Fornecedor</span>
              <span className="accordion-title">{item?.creditor_name}</span>
            </div>
          </div>
        </div>
        <div className="margin-right-16">
          <span className="accordion-label">Valor</span>
          <span className="accordion-title">{formatNumberToCurrency(item?.total_value)}</span>
        </div>
      </div>
      {toggle && item?.items?.map((i, idx) => <DocumentAccordion key={i.doc} item={i} />)}
    </div>
  }

  return <Item item={item} />
}

export default CreditorAccordion;