import DashboardUIProvider from "@contexts/dashboardUI"
import ProjectProvider from "@contexts/projects"
import Dashboard from "./Dashboard"
import CostsProvider from "@contexts/costs"
import BimProvider from "@contexts/bim"

const DashboardContainer = () => (
  <DashboardUIProvider>
    <BimProvider>
      <CostsProvider>
        <ProjectProvider>
          <Dashboard />
        </ProjectProvider>
      </CostsProvider>
    </BimProvider>
  </DashboardUIProvider>
)
export default DashboardContainer