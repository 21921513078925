
import { useCostsActions, useCostsContext } from "@contexts/costs";
import { randomKey } from "@utils/random";
import { useEffect, useState } from "react";

const DefaultRow = ({ item, edit, value, custom }) => {
  const { field, label, type, options } = item

  if (type === 'select') {
    return <div>
      <label>{label}</label>
      <select onChange={edit} name={field} value={value}>
        <option disabled value=''>Selecione uma opção</option>
        {options?.map(i => {
          return <option value={i}>{i}</option>
        })}
      </select>
    </div>
  }
  return <div>
    <label>{label}</label>
    <input onChange={edit} placeholder={label} name={field} value={value} type={type} />
  </div>
}

const CustomRow = ({ item, edit, idx, onDelete }) => {
  const { field, value } = item
  return (
    <div className="grid columns-2-tablet">
      <div>
        <input
          onChange={(e) => edit(e, idx, 'field')}
          placeholder="Nome do campo"
          value={field}
          type="text"
        />
        <input
          onChange={(e) => edit(e, idx, 'value')}
          placeholder="Valor"
          value={value}
          type="text"
        />
      </div>
      <button onClick={(e) => onDelete(idx)}>Deletar</button>
    </div>
  );
}

const defaultBuilding = {
  name: "",
  company: '',
  indexer: '',
  city: '',
  state: '',
  budget_type: '',
  type: '',
  standard: '',
  date: '',
  terrain_area: 0,
  build_area: 0,
  total_floors: 0,
  type_floors: 0,
  unique_floors: 0,
  unique_floors_area: 0,
  duplex: 0,
  tower_projection: 0,
  tower_perimeter: 0,
  inside_area: 0,
  outside_area: 0,
  underground_area: 0,
  underground_floors: 0,
  basement_area: 0,
  basement_perimeter: 0,
  basement_floors: 0,
  rooms: 0,
  custom: []
}

const Entry = () => {
  const { updateBuilding, getIndexes, updateIndex } = useCostsActions();
  const { currentBuilding } = useCostsContext()
  const [buildingInfo, setBuildingInfo] = useState(defaultBuilding)
  const [indexes, setIndexes] = useState([])
  const [newCustom, setNewCustom] = useState({
    type: 'new',
    value: ''
  })
  const fields = [
    { label: 'Nome', field: 'name', type: 'text' },
    { label: 'Construtora', field: 'company', type: 'text' },
    { label: 'Indexador', field: 'indexer', type: 'text' },
    { label: 'Cidade', field: 'city', type: 'text' },
    { label: 'Estado', field: 'state', type: 'text' },
    { label: 'Tipo de Orçamento', field: 'budget_type', type: 'text' },
    { label: 'Tipo de Obra', field: 'type', type: 'select', options: ['Residencial', 'Comercial'] },
    { label: 'Padrão da Obra', field: 'standard', type: 'select', options: ['Alto', 'Médio-alto', 'Médio'] },
    { label: 'Data', field: 'date', type: 'date' },
    { label: 'Área do Terreno', field: 'terrain_area', type: 'number' },
    { label: 'Área Total Construída', field: 'build_area', type: 'number' },
    { label: 'Pavimentos', field: 'total_floors', type: 'number' },
    { label: 'Pavimentos Tipo', field: 'type_floors', type: 'number' },
    { label: 'Pavimentos Diferenciados', field: 'unique_floors', type: 'number' },
    { label: "Área Pavimentos Diferenciados", field: "unique_floors_area", type: 'number' },
    { label: "Duplex", field: "duplex", type: 'number' },
    { label: "Projeção da Torre", field: "tower_projection", type: 'number' },
    { label: "Área de Lazer Interna", field: "inside_area", type: 'number' },
    { label: "Área de Lazer Externa", field: "outside_area", type: 'number' },
    { label: "Área de Subsolos", field: "underground_area", type: 'number' },
    { label: "Subsolos", field: "underground_floors", type: 'number' },
    { label: "Área de Projeção de Embasamento", field: "basement_area", type: 'number' },
    { label: "Perímetro de Projeção do Embasamento", field: "basement_perimeter", type: 'number' },
    { label: "Número de Pavimentos de Embasamento", field: "basement_floors", type: 'number' },
    { label: "Número de Apartamentos", field: "rooms", type: 'number' },
  ]

  const handleBuildingEdit = (event) => {
    event.preventDefault();

    setBuildingInfo(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const editCustom = (e, idx, type) => {
    e.preventDefault();
    setBuildingInfo((prev) => ({
      ...prev,
      custom: prev.custom.map((item, i) => {
        return i === idx ? { ...item, [type]: e.target.value } : item
      }
      ),
    }));
  };

  const handleAddCustom = async () => {
    if (newCustom.trim() === '') return
    const newField = [...buildingInfo.custom, { field: newCustom, value: '' }]
    setBuildingInfo(prev => ({ ...prev, custom: newField }))
    if (!indexes.map(i => i.name).includes(newCustom)) {
      await updateIndex(newCustom)
    }
  }

  const getIndex = async () => {
    const res = await getIndexes()
    setIndexes(res.res)
  }

  useEffect(() => {
    if (!currentBuilding.custom) {
      setBuildingInfo({ ...currentBuilding, custom: [] })
    } else {
      setBuildingInfo({ ...currentBuilding })
    }
    getIndex()
  }, [currentBuilding])


  const reset = () => {
    setBuildingInfo(defaultBuilding)
  }

  const handleSend = async () => {
    await updateBuilding(buildingInfo)
  }

  const handleDeleteCustom = (idx) => {
    setBuildingInfo((prev) => ({
      ...prev,
      custom: prev.custom.filter((item, i) => i !== idx)
    }));
  };

  const handleNewCustom = (e) => {
    setNewCustom(e.target.value)
  }

  return <div className="costs">
    <h2>Dados de Entrada</h2>
    <div className="grid columns-5-tablet gap-16 align-items-end">
      {fields.map(i => {
        return <DefaultRow key={i.field} item={i} options={i.options} value={buildingInfo[i.field]} edit={handleBuildingEdit} />
      })}
    </div>
    <h2>Campos Personalizados</h2>
    <div className="grid columns-3-tablet gap-16 margin-bottom-48 align-items-end">
      <div>
        <label>Adicionar do banco</label>
        <select defaultValue="" onChange={handleNewCustom}>
          <option value="" disabled>Selecione</option>
          {indexes.map(i => {
            return <option value={i.name} key={i.name}>{i.name}</option>
          })}
        </select>
      </div>
      <div>
        <label>Adicionar personalizado</label>
        <input onChange={handleNewCustom} placeholder="custom" />
      </div>
      <button onClick={handleAddCustom} className="button button-accent">Adicionar campo</button>
      {buildingInfo.custom?.length > 0 && buildingInfo.custom.map((i, idx) => {
        return <CustomRow key={`customField${idx}`} item={i} value={i.value} edit={editCustom} idx={idx} onDelete={handleDeleteCustom} />
      })}
    </div>
    <div className="flex flex-end margin-right-24">
      <button onClick={reset} className="button button-accent margin-right-24">Nova Obra</button>
      <button onClick={handleSend} className="button button-accent">{currentBuilding.name === buildingInfo.name ? 'Atualizar Obra' : "Enviar Obra"}</button>
    </div>

  </div>
}

export default Entry