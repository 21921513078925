import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import AccordionItem from "./AccordionItem";
import ServiceAccordion from "./ServiceAccordion";
import { useDashboardUIContext } from "@contexts/dashboardUI";

const SubstepAccordion = ({parent}) => {
  
  const { barChartDate } = useDashboardUIContext()
  const {activeProject: {_id, substeps = {}}, filterDates} = useProjectContext();
  const { loadSubsteps } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadSubsteps(parent, barChartDate);
    } 
  },[_id, filterDates, barChartDate])

  const stepSubsteps = useMemo(() => substeps[parent], [parent, substeps])

  return (
    <>
      {!stepSubsteps && <LoaderItem />}
      {stepSubsteps?.map((item, index) => <AccordionItem key={index} item={item} type={"substep"} typeTitle={"Subetapa"} ChildAccordionComponent={ServiceAccordion}/>)}
    </>
  )
}

export default SubstepAccordion;