import { useState } from "react"
import { renderRow } from "../defaults"
import { formatNumberToLocale } from "@utils/formatNumber"
import Tooltip from "@components/dashboard/tooltip"
import { useProjectContext } from "@contexts/projects"

const EapResources = ({ item, appropriation_level, divisions }) => {
  const [visibleDivisions, setVisibleDivisions] = useState({
    both: false,
    resource: false,
    attended: false
  })

  const formatWbs = (str) => {
    if (str?.length <= 3) return str;
    if (str?.length <= 5) return `${str.slice(0, 3)}.${str.slice(3)}`;
    if (str?.length <= 8) return `${str.slice(0, 3)}.${str.slice(3, 5)}.${str.slice(5)}`;
    if (str?.length <= 11) return `${str.slice(0, 3)}.${str.slice(3, 5)}.${str.slice(5, 8)}.${str.slice(8, 11)}`;
    return `${str.slice(0, 3)}.${str.slice(3, 5)}.${str.slice(5, 8)}.${str.slice(8, 11)}.${str.slice(11)}`;
  };

  const findPath = (e) => {
    const [unit, cell, step, substep, service] = formatWbs(e).split('.')
    let path = divisions.find(i => i.unit.slice(0, 3) === unit && i.cell.slice(0, 2) === cell && i.step.slice(0, 3) === step && i.substep.slice(0, 3) === substep)
    let res = `${path?.unit}\n${path?.cell}\n${path?.step}\n${path?.substep}`
    if (service) {
      path = divisions.find(i => i.unit.slice(0, 3) === unit && i.cell.slice(0, 2) === cell && i.step.slice(0, 3) === step && i.substep.slice(0, 3) === substep && i.service.slice(0, 3) === service)
      res = `${path?.unit}\n${path?.cell}\n${path?.step}\n${path?.substep}\n${path?.service}`
    }
    return res
  }

  return <div>
    <h3 className="margin-bottom-8">Total</h3>
    <div className="accordion">
      <div className="accordion-item offcanvas-accordion-item">
        <div className="accordion-content columns-2-tablet">
          <h2 className="accordion-sub-header">
            Orçado
          </h2>
          <div />
          <div />
          <h2 className="accordion-sub-header">
            Apropriado
          </h2>
        </div>
        <div className="accordion-content columns-6-tablet margin-top-12">
          {renderRow(`Quantidade`, formatNumberToLocale(item.evaluated_quantity))}
          {renderRow('Unitário (R$)', formatNumberToLocale(item.evaluated_total / item.evaluated_quantity))}
          {renderRow('Total (R$)', formatNumberToLocale(item.evaluated_total))}
          {renderRow('Quantidade', formatNumberToLocale(item.attended_quantity))}
          {renderRow('Unitário (R$)', formatNumberToLocale(item.attended_total / item.attended_quantity))}
          {renderRow('Total (R$)', formatNumberToLocale(item.attended_total))}
        </div>
      </div>
    </div>
    <h3 onClick={() => setVisibleDivisions({ ...visibleDivisions, resource: !visibleDivisions.resource })}><i class={`eap-division-button ${visibleDivisions.resource ? 'active' : ''}`} />Orçados ({item?.evaluated_items?.length})</h3>
    <div className={`accordion margin-top-16 divisions-container ${visibleDivisions.resource ? 'active' : 'hidden'}`}>
      {item?.evaluated_items?.map(i => {
        return <div className="accordion-item margin-bottom-8 offcanvas-accordion-item">
          <div>
            <div className="flex align-center gap-8">
              <span className="accordion-title margin-top-0" title={findPath(i.wbs_code.replace(item.building_wbs, ''))}>{formatWbs(i.wbs_code.replace(item.building_wbs, ''))}<Tooltip /></span>
            </div>
          </div>
          <div className="accordion-content columns-5-tablet margin-top-12">
            {renderRow(`Quantidade`, formatNumberToLocale(i.quantity))}
            {renderRow('Unitário (R$)', formatNumberToLocale(i.value))}
            {renderRow('Total (R$)', formatNumberToLocale(i.total_value))}
            {renderRow('Média Indexada (R$)', formatNumberToLocale(i.indexed_value / i.quantity))}
            {renderRow('Total Indexado (R$)', formatNumberToLocale(i.indexed_value))}
          </div>
        </div>
      })}
    </ div>
    <h3 onClick={() => setVisibleDivisions({ ...visibleDivisions, attended: !visibleDivisions.attended })}><i class={`eap-division-button ${visibleDivisions.attended ? 'active' : ''}`} />Apropriados ({item?.attended_items?.length})</h3>
    <div className={`accordion margin-top-16 divisions-container ${visibleDivisions.attended ? 'active' : 'hidden'}`}>
      {item?.attended_items?.map((i, index) => {
        return <div key={index} className="accordion-item offcanvas-accordion-item">
          <div>
            <div className="flex align-center gap-8">
              <span className="accordion-title margin-top-0" title={findPath(i.wbs_code.replace(item.building_wbs, ''))}>{formatWbs(i.wbs_code.replace(item.building_wbs, ''))}<Tooltip /></span>
            </div>
          </div><div className="accordion-content columns-5-tablet margin-top-12">
            {renderRow(`Quantidade `, formatNumberToLocale(i.quantity))}
            {renderRow('Unitário (R$)', formatNumberToLocale(i.value))}
            {renderRow('Total (R$)', formatNumberToLocale(i.total_value))}
            {renderRow('Média Indexada (R$)', formatNumberToLocale(i.indexed_value / i.quantity))}
            {renderRow('Total Indexado (R$)', formatNumberToLocale(i.indexed_value))}
          </div></div>
      })}
    </div>

  </div>
}

export default EapResources