import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./App";

// const container = document.getElementById("root");
const root = createRoot(document.getElementById("app"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// import React, { Fragment, useEffect } from "react";
// import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import Login from "./pages/Login";
// import Dashboard from "./pages/Dashboard";
// import Value from "./pages/Value";
// import ValueValidation from "./pages/ValueValidation";
// import { Helmet } from "./components/Helmet";
// import FinancialProjection from "./pages/FinancialProjection";
// import "./assets/scss/styles.scss";

// const isLogged = true;

// const Redirect = ({ to }) => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate(to);
//   });
//   return null;
// };

// root.render(
//   <Fragment>
//     <Helmet />
//     <BrowserRouter>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route path="/dashboard" element={<Dashboard />} />
//         <Route path="/value" element={<Value />} />
//         <Route path="/valueValidation" element={<ValueValidation />} />
//         <Route path="/projection" element={<FinancialProjection />} />
//       </Routes>
//     </BrowserRouter>
//   </Fragment>
// );
