import { useEffect, useMemo } from "react";
import LoaderItem from "./LoaderItem";
import { useProjectActions, useProjectContext } from "@contexts/projects";
import AccordionItem from "./AccordionItem";
import SubstepAccordion from "./SubstepAccordion";
import { useDashboardUIContext } from "@contexts/dashboardUI";

const StepAccordion = ({parent}) => {
  
  const { barChartDate } = useDashboardUIContext()
  const {activeProject: {_id, steps = {}}, filterDates} = useProjectContext();
  const { loadSteps } = useProjectActions();

  useEffect(() => {
    if(_id) {
      loadSteps(parent, barChartDate);
    } 
  },[_id, filterDates, barChartDate])

  const cellSteps = useMemo(() => steps[parent], [steps, parent])

  return (
    <>
      {!cellSteps && <LoaderItem />}
      {cellSteps?.map((item, index) => <AccordionItem key={index} item={item} type={"step"} typeTitle={"Etapa"} ChildAccordionComponent={SubstepAccordion}/>)}
    </>
  )
}

export default StepAccordion;