// import getService from "@services/project/get";
// import createService from "@services/project/create";
// import developerListService from "@services/project/developerList";
// import listService from "@services/project/list";
// import updateService from "@services/project/update";
// import getSignedUrlService from "@services/project/getSignedUrl";
// import sendFileService from "@services/project/sendFile";
// import createFileRecordService from "@services/project/createFileRecord";
// import getFileSignedUrlService from "@services/project/getFileSignedUrl";

export default (state, dispatch) => {
  const toggleSidebar = () => dispatch({
    type: "TOGGLE_SIDEBAR"
  })

  const toggleOffcanvas = () => dispatch({
    type: "TOGGLE_OFFCANVAS"
  })

  const toggleItemDetailOffcanvas = () => dispatch({
    type: "TOGGLE_ITEM_DETAIL_OFFCANVAS"
  })

  const toggleHelpModal = () => dispatch({
    type: "TOGGLE_HELP_MODAL"
  })

  const setIsLoading = (value) => dispatch({
    type: "SET_IS_LOADING",
    data: value
  })

  const setBarChartDate = (value) => dispatch({
    type: "SET_BAR_CHART_DATE",
    data: value
  })

  return {
    toggleSidebar,
    toggleOffcanvas,
    toggleHelpModal,
    setIsLoading,
    toggleItemDetailOffcanvas,
    setBarChartDate
  };
};
