import Card from "@components/Card";
import { getIndicatorColor } from "@utils/colorCalculations";
import { roundValue } from "@utils/formatNumber";

const { useMemo } = require("react")

const IndicatorCard = ({ title, indicator, black, tooltip }) => {
  const color = useMemo(() => getIndicatorColor(indicator), [indicator])

  return (
    <Card label={title} content={roundValue(indicator ?? 0)} color={black ? 'black' : color} tooltip={tooltip} />
    // <div className="card">
    //   <span className="card-title">{title}</span>
    //   <span className={`card-value large color-${black ? 'black' : color}`}>{roundValue(indicator ?? 0)}</span>
    //   {/* <span className={`card-value large`}>{roundValue(indicator)}</span> */}
    // </div>
  )
}

export default IndicatorCard;