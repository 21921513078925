export const ensureDateType = (value) => {
  if (typeof value === "object") return value;
  if (!value) return

  const [year, month, day] = value.slice(0, 10).split("-")
  return new Date(Date.UTC(year, month - 1, day));
}

export const formatDate = (value) => {
  if (!value) return
  const date = ensureDateType(value)
  return date.toLocaleDateString("pt-BR", { timeZone: "UTC" })
};

export const formatDateMY = (value) => {
  if (!value) return
  const date = ensureDateType(value)
  return date.toLocaleDateString("pt-BR", { timeZone: "UTC", month: "short", year: "numeric" })
}

export const formatDateDMY = (value) => {
  if (!value) return
  const date = ensureDateType(value)
  return date.toLocaleDateString("pt-BR", { timeZone: "UTC", day: "numeric", month: "numeric", year: "numeric" })
}

export const formatDateToNumberMMYY = (value) => {
  if (!value) return
  const date = ensureDateType(value);
  const formatedDate = `${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
  return formatedDate.length > 6 ? formatedDate : `0${formatedDate}`
}