export const translateStatus = (status) => {
  let res;
  switch (status) {
    case 'not-started':
      res = 'Não Iniciado'
      break;
    case 'ongoing':
      res = 'Em andamento'
      break;
    case 'on-hold':
      res = 'Paralisado'
      break;
    case 'done':
      res = 'Concluído'
      break;
    default:
      break;
  }
  return res
}