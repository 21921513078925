import { useCostsActions } from "@contexts/costs";
import { useEffect, useState } from "react";

const defaultIndexer = {
  state: '',
  value: 0,
  date: ''
}

const Indexers = () => {
  const { getIndexers, updateIndexer } = useCostsActions();
  const [indexers, setIndexers] = useState([])
  const [newIndexer, setNewIndexer] = useState(defaultIndexer)

  const getCub = async () => {
    const res = await getIndexers()
    const states = [...new Set(res.res.map(i => i.state))]
    let arr = [];
    states.forEach(i => {
      arr.push({ state: i, cubs: res.res.filter(j => j.state === i) })
    });
    setIndexers(arr)

  }

  const handleSend = async () => {
    const res = await updateIndexer(newIndexer)
  }

  useEffect(() => {
    getCub()
  }, [])


  const handleEdit = (event) => {
    event.preventDefault();

    setNewIndexer(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  }

  return <div className="costs">
    <h2>CUB</h2>
    <div className="grid columns-4-tablet gap-16">
      <div>
        <label>Estado</label>
        <input value={newIndexer.state} name='state' onChange={handleEdit} />
      </div>
      <div>
        <label>Valor</label>
        <input value={newIndexer.value} type="number" name='value' onChange={handleEdit} />
      </div>
      <div>
        <label>Data</label>
        <input value={newIndexer.date} type="month" name='date' onChange={handleEdit} />
      </div>
      <button onClick={handleSend} className="button-accent">Enviar</button>
    </div>
    {indexers.map(i => {
      return <div>
        <h2>{i.state}</h2>
        {i.cubs.map(j => {
          return <div className="flex gap-16">
            <p>Mês: {j.date}</p>
            <p>Valor: {j.value}</p>
          </div>
        })}
      </div>
    })}
  </div>
}

export default Indexers;