import { Line } from "react-chartjs-2";

import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

import BasicInfo from "@components/dashboard/details/BasicInfo";
import Forecast from "@components/dashboard/details/Forecast";
import FinancialInfo from "@components/dashboard/details/FinancialInfo";

import { useProjectContext } from "@contexts/projects";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const Details = () => {
  const { activeProject } = useProjectContext()
  if (!activeProject) return <></>

  return (
    <>
      <BasicInfo />
      <Forecast />
      <FinancialInfo />
    </>
  );
};

export default Details;
