import { useProjectContext } from "@contexts/projects";
import { formatNumberToLocale } from "@utils/formatNumber";
import { useState } from "react";


export const Accordion = ({ item, count }) => {
  const [isOpen, setIsOpen] = useState(count == 1);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  if (item.title === 'undefined - undefined') return

  return (
    <>
      <span onClick={toggleAccordion} className="eap-item">
        <span className="eap-type">{item.type}</span> {item.title}
      </span>
      {isOpen && item.subs && (
        <div>
          {item.subs.map(subItem => (
            <Accordion key={subItem.title} item={subItem} count={count} />
          ))}
        </div>
      )}
    </>
  );
};

export const renderRow = (label, value) => {
  return <div className="data">
    <span className="label">{label}</span>
    <span className="value">{value}</span>
  </div>
}

const getLength = (value) => {
  const formated = formatNumberToLocale(value ?? 0)
  if (formated.length > 13) return 'font-s'
  if (formated.length === 13) return 'font-m'
  return ''
}

export const renderCard = (label, value) => {
  return <div className="card">
    <span className="card-title">{label}</span>
    <span className={`card-value ${getLength(value ?? 0)} ${label === 'IDC' ? (value < 1 ? 'color-red' : 'color-green') : undefined}`}>{formatNumberToLocale(value ?? 0)}</span>
  </div>
}

export const formatWBS = (wbs) => {
  const { selectedDetail: activeProject } = useProjectContext();

  if (!wbs) return
  const wbs_id = wbs?.replace(activeProject.wbs_code_id, '') ?? 0

  if (wbs_id.length === 11) {
    return wbs_id.replace(/(\d{3})(\d{2})(\d{3})(\d{3})/, '$1.$2.$3.$4');
  } else {
    return wbs_id.replace(/(\d{3})(\d{2})(\d{3})(\d{3})(\d{3})/, '$1.$2.$3.$4.$5');
  }
}