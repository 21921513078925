import Loader from "@components/Loader";
import { useAuthActions, useAuthContext } from "@contexts/auth";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const AlphaKey = () => {
  const { alphaKeyError, alphaKeyEmail } = useAuthContext();
  const { checkAlphaKey } = useAuthActions();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alphaKey, setAlphaKey] = useState("")

  useEffect(() => {
    if(alphaKeyEmail) {
      navigate("/access/cadastrar");
    }
  }, [alphaKeyEmail])

  const submitForm = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true)
    await checkAlphaKey(alphaKey)
    setIsLoading(false)
  }, [alphaKey])

  return (
    <form id="access-form">
      {isLoading && <Loader />}
      <hgroup>
        <h1>Chave Alfa</h1>
        <h2 className="margin-top-16">
          Para continuar, use a chave alfa do convite que você recebeu em seu e-mail.
        </h2>
      </hgroup>
      <fieldset className="grid gap-16 margin-top-32">
        <label>
          <span>Insira sua chave</span>
          <input type="text" name="key" value={alphaKey} onChange={({target: {value}}) => setAlphaKey(value)} required={true} />
        </label>
      </fieldset>
      {alphaKeyError && (
        <span>{alphaKeyError}</span>
      )}
      <div className="flex align-center justify-content-space-between gap-16 wrap-wrap margin-top-32">
        <Link to="/access" className="text-small">
          Voltar
        </Link>
        <button className="button button-accent" onClick={submitForm}>Continuar</button>
      </div>
      <p className="text-small margin-top-32">
        Apenas participantes de nosso Programa Alfa podem acessar as
        funcionalidades disponíveis atualmente. Se você quer saber mais sobre
        como participar, acesse o site da{" "}
        <Link to="https://www.ampliengenharia.com">Ampli</Link> e faça seu
        registro.
      </p>
    </form>
  );
};

export default AlphaKey;
