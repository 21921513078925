import { useAuthActions, useAuthContext } from "@contexts/auth";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import Loader from "@components/Loader";

const Login = () => {
  const { error } = useAuthContext();
  const { login } = useAuthActions();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    keepConnected: false,
  });

  const handleFormChange = useCallback(
    ({ target: { name: key, value, checked, type } }) => {
      setFormData({
        ...formData,
        [key]: type === "checkbox" ? checked : value,
      });
    },
    [formData],
  );

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      await login(formData);
      setIsLoading(false);
    },
    [formData],
  );

  return (
    <form id="access-form">
      {isLoading && <Loader />}

      <hgroup>
        <h1>Entrar</h1>
        <h2 className="margin-top-16">
          Primeira vez aqui? <Link to="chave-alfa">Use sua Chave Alfa</Link>
        </h2>
      </hgroup>
      {error && (
        <div className="alert alert-error margin-top-32">
          <h3 className="info-title">{error.title}</h3>
          <p>{error.label}</p>
        </div>
      )}
      <fieldset className="grid gap-16 margin-top-32">
        <label>
          <span>E-mail</span>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleFormChange}
            required={true}
          />
        </label>
        <label>
          <span>Senha</span>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleFormChange}
            required={true}
          />
        </label>
        <label className="flex align-center width-fit">
          <input
            type="checkbox"
            name="keepConnected"
            checked={formData.keepConnected}
            onChange={handleFormChange}
          />
          <span className="text-small margin-left-4">Manter conectado</span>
        </label>
      </fieldset>
      <div className="flex align-center justify-content-space-between gap-16 wrap-wrap margin-top-32">
        {/* <Link to="recuperar-senha" className="text-small">
          Recuperar senha
        </Link> */}
        <button className="button button-accent" onClick={handleLogin}>
          Entrar
        </button>
      </div>
      <p className="text-small margin-top-32">
        Apenas participantes de nosso Programa Alfa podem acessar as
        funcionalidades disponíveis atualmente. Se você quer saber mais sobre
        como participar, acesse o site da{" "}
        <Link to="https://www.ampliengenharia.com">Ampli</Link> e faça seu
        registro.
      </p>
    </form>
  );
};

export default Login;
