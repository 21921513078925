import { useProjectActions, useProjectContext } from "@contexts/projects";
import { ensureDateType } from "@utils/formatDate";
import { useCallback } from "react";

const formatDateToInput = (value) => {
  const date = ensureDateType(value);
  return date.toISOString().slice(0, 7);
}

const formatInputToDate = (value) => {
  const [year, month] = value.split("-");
  return new Date(Date.UTC(year, month - 1, 1))
}

const DateRangeFilter = () => {
  const { activeProject: { _id: projectId, start_date, end_date, prediction_of_the_end, lastPayment }, filterDates } = useProjectContext();
  if (!start_date || !end_date || !prediction_of_the_end || !lastPayment) return
  const { changeDates } = useProjectActions();

  const [month, year] = lastPayment?.date.split("/");

  // Criar uma data no primeiro dia do mês, no fuso horário UTC
  const lastDate = new Date(Date.UTC(year, month - 1, 1)).toISOString();

  // if (new Date(end_date) > new Date(prediction_of_the_end)) {
  //   lastDate = end_date
  // } else {
  //   lastDate = prediction_of_the_end
  // }


  const changeStart = useCallback(({ target: { value } }) => {
    if (value.length <= 0) return;
    const startDate = formatInputToDate(value);
    changeDates(projectId, startDate, filterDates.endAt)
  }, [projectId, filterDates.endAt])

  const changeEnd = useCallback(({ target: { value } }) => {
    if (value.length <= 0) return;
    const endDate = formatInputToDate(value);
    changeDates(projectId, filterDates.beginAt, endDate)
  }, [projectId, filterDates.beginAt])

  return (
    <form className="date-range">
      <h3>Periodo:</h3>
      <input
        type="month"
        required={true}
        min={formatDateToInput(start_date)}
        max={formatDateToInput(filterDates.endAt)}
        value={formatDateToInput(filterDates.beginAt)}
        onChange={changeStart}
      />
      <span className="color-gray">-</span>
      <input
        type="month"
        required
        min={formatDateToInput(filterDates.beginAt)}
        max={formatDateToInput(lastDate)}
        value={formatDateToInput(filterDates.endAt)}
        onChange={changeEnd}
      />
    </form>
  )
};

export default DateRangeFilter;
